.fileselector {
  width: 820px;
  max-width: 100%;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid grey;
  padding: 10px 0;
  border-radius: 5px 5px 0 0;
  height: 250px;
  overflow: auto;
}

.fileselector img {
  width: 15px;
  padding-right: 10px;
}

.fileselector .file {
  margin: 4px 0 4px 15px;
}

.fileselector .folder {
  margin: 4px 0 4px 15px;
}

.fileselector .folder.selectable {
  cursor: pointer;
}

.fileselector .folder .dropdown {
  display: inline-block;
  font-size: 18px;
  padding-right: 10px;
  cursor: pointer;
}

.fileselector .file .file-checkbox {
  width: auto;
  margin-right: 10px;
}
