.overlay {
  fill: none;
  pointer-events: all;
}

#legend {
  position: absolute;
  top: 50px;
  left: 70px;
  background-color: white;
  padding: 3px;
  cursor: pointer;
}

.vis-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vis-main.full {
  position: fixed;
  left: 0;
  top: 40px;
  width: 100%;
  height: calc(100% - 40px);
  background-color: white;
  z-index: 9999999;
  padding: 20px;
  box-sizing: border-box;
}

.vis-header table.downloadtable {
  width: 100%;
  text-align: center;
}

.vis-header table.downloadtable td.title {
  text-decoration: underline;
}

.vis-header table td {
  position: relative;
  vertical-align: top;
}

.vis-header img {
  height: 18px;
  cursor: pointer;
}

.vis-header .downloadbar {
  position: absolute;
  top: 30px;
  right: -58px;
  z-index: 99;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 250px;
  box-sizing: border-box;
  cursor: auto;
}

.vis-header .downloadbar.hide {
  display: none;
}

.fontsize {
  position: relative;
}

.fontsize:hover .fontsize-dropdown {
  display: block;
}

.fontsize .fontsize-dropdown .sel {
  font-weight: bold;
}

.fontsize .fontsize-dropdown {
  display: none;
  position: absolute;
  top: 25px;
  left: 0px;
  width: 25px;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  padding: 4px 0;
  border-radius: 4px;
  z-index: 999999;
}
.fontsize .fontsize-dropdown div:hover {
  font-weight: bold;
}

.fontsize .fontsize-dropdown div {
  cursor: pointer;
  padding: 2px 0;
}

.graphhelpbar {
  font-size: 18px;
  cursor: pointer;
}

.graphhelpbar table {
  font-size: 14px;
  text-align: left;
}

.graphhelpbar:hover .graphhelp {
  display: block;
}

.graphhelp {
  z-index: 3;
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  width: 300px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  padding: 10px;
  max-width: 100vw;
  box-sizing: border-box;
}

.vis-header .downloadbar button {
  font-size: 10px;
  padding: 3px 5px;
  width: 50px;
  margin: 15px 10px 5px 10px;
  background-color: #fff;
  display: inline-block;
}

.vis-header .downloadbar button:hover {
  background-color: rgb(240, 240, 240);
}

.vis-header .downloadbar button.red {
  border-color: #fb0000;
  color: #fb0000;
}

.vis-header .downloadbar button.blue {
  border-color: #28b5f5;
  color: #28b5f5;
}
