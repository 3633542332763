.mapselect {
  width: 100%;
  height: calc(100vh - 370px);
  min-height: 500px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 5px;
  border: solid 1px rgb(169, 169, 169);
}


.leaflet-draw {
  display: none;
}

.leaflet-draw-tooltip {
  display: none;
}

.closemap {
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  border-radius: 4px;
  font-size: 20px;
}

.closemap:hover {
  color: grey;
}
