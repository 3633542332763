.addlayers .filterbox.inner .title {
  font-size: 22px;
  padding: 10px 0;
}

.addlayers .addlayers-box {
  border: 1px solid grey;
}

.addlayers-titlebar {
  padding: 12px 10px;
  cursor: pointer;
}

.addlayers-titlebar:hover {
  background-color: rgba(222, 235, 255);
}

.addlayers-title {
  display: inline-block;
  max-width: calc(100% - 50px);
}

.addlayers-symbol {
  float: right;
  width: 50px;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
}

.addlayers-detail {
  cursor: pointer;
  position: relative;
  background-color: #f2f5f7;
  border-radius: 5px;
  padding: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  box-sizing: border-box;
  margin: 10px;
}

.addlayers-detail:hover {
  box-shadow: rgb(0 0 0 / 44%) 0px 3px 8px;
}

.addlayers-detail table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
}

.addlayers-detail table td {
  width: 33%;
}
