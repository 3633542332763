.shading {
  height: 250px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 990;
}

.datetimedepthselector {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 994;
  min-width: 300px;
}

.datetimedepthselector .timeselectionbar {
  padding: 0 50px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  cursor: pointer;
}

.datetimedepthselector:hover .timeselectionbar {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.datetimedepthselector .timeselectionbar .timeselector .xaxis {
  color: white;
  font-size: 14px;
}

.datetimedepthselector .controlbar {
  padding: 20px 50px;
}

.datetimedepthselector .controlbar .playpause {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 20px;
}

.datetimedepthselector .controlbar .playpause:hover {
  transform: scale(1.2);
}

.datetimedepthselector .controlbar .playpause img {
  height: 30px;
  margin-bottom: -4px;
}

.datetimedepthselector .controlbar .skip {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 20px;
  position: relative;
  margin: 0 20px;
}

.datetimedepthselector .controlbar .skip.right {
  width: 80px;
}

.datetimedepthselector .controlbar .skip .timestep-text.right {
  position: absolute;
  top: 12px;
  width: 100%;
  left: 10px;
  color: white;
  font-size: 18px;
}

.datetimedepthselector .controlbar .skip:hover {
  transform: scale(1.1);
}

.datetimedepthselector .controlbar .skip img {
  height: 35px;
  margin-bottom: -5px;
}

.datetimedepthselector .controlbar .datetime {
  display: inline-block;
  color: white;
  font-size: 25px;
  padding-bottom: 3px;
  width: calc(100% - 450px);
  text-align: center;
}

.datetimedepthselector .controlbar .datetime .text {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.datetimedepthselector .controlbar .datetime .text:hover {
  transform: scale(1.02);
}

.datetimedepthselector .controlbar .depthtimestep {
  color: white;
  font-size: 25px;
  padding-bottom: 3px;
  float: right;
}

.datetimedepthselector .controlbar .depthtimestep .text {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.datetimedepthselector .controlbar .depthtimestep .text:hover {
  transform: scale(1.1);
}

.datetimedepthselector .controlbar .depthtimestep img {
  height: 20px;
}

.datetimedepthselector .controlbar .depthtimestep img.clock {
  height: 28px;
  margin-bottom: -4px;
}

.selectormodal {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.selectormodal td.modalfull {
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.selectormodal .selectorbox {
  width: fit-content;
  margin: auto;
  color: white;
}

.selectormodal .selectorbox .closemodal {
  width: 100%;
  cursor: pointer;
}

.selectormodal .selectorbox .closemodal .icon {
  float: right;
  font-size: 28px;
}

.selectormodal .selectorbox .editor {
  padding: 30px;
  max-width: 650px;
}

.selectormodal .selectorbox .editor.time {
  font-size: 50px;
}

.selectormodal .selectorbox .editor td.modalarrow {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 25px;
}

.selectormodal .selectorbox .editor td.modalarrow:hover {
  transform: scale(1.1);
}

.selectormodal .selectorbox .editor.timestep {
  font-size: 16px;
  max-width: 350px;
}

.selectormodal .selectorbox .editor.timestep .timesteplist {
  display: flex;
  flex-flow: wrap;
  align-content: flex-start;
  transform-origin: top left;
  justify-content: center;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item {
  border: 1px solid white;
  padding: 8px 12px;
  margin: 10px;
  cursor: pointer;
  width: 70px;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item.selected {
  background-color: #006edc;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item:hover {
  background-color: #006edc;
}

.selectormodal .selectorbox .editor.depth input {
  color: white;
  background-color: transparent;
}

.editor .react-calendar {
  background-color: rgba(0, 0, 0, 0.4);
}

.editor .react-calendar__month-view__days {
  justify-content: center;
}

.editor .react-calendar button {
  color: white;
  border: none;
}

.editor .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #006edc;
}

.editor .react-calendar__navigation button:enabled:hover,
.editor .react-calendar__navigation button:enabled:focus {
  background-color: #76baff;
}

.depthselector {
  height: 70vh;
  width: 300px;
}

@media screen and (max-width: 1000px) {
  .datetimedepthselector .controlbar .depthtimestep {
    font-size: 18px;
  }
  .datetimedepthselector .controlbar .datetime {
    font-size: 22px;
    width: 150px;
  }
  .datetimedepthselector .controlbar .playpause img {
    height: 20px;
    margin-bottom: -2px;
  }
  .datetimedepthselector .controlbar .depthtimestep img {
    height: 15px;
  }
  .datetimedepthselector .controlbar {
    padding: 20px 20px;
  }
  .datetimedepthselector .controlbar .skip {
    margin: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .datetimedepthselector .controlbar .datetime {
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;
  }
  .datetimedepthselector .timeselectionbar {
    display: none;
  }
  .datetimedepthselector .controlbar .skip img {
    height: 35px;
  }
  .datetimedepthselector .controlbar .playpause {
    margin-right: 15px;
  }
  .datetimedepthselector .controlbar .playpause img {
    height: 35px;
    margin-bottom: -5px;
  }

  .datetimedepthselector .controlbar .depthtimestep img {
    height: 25px;
    margin-bottom: -5px;
  }

  .datetimedepthselector .controlbar .depthtimestep {
    padding-top: 3px;
  }

  .datetimedepthselector .controlbar .skip .timestep-text.right {
    display: none;
  }
  .datetimedepthselector .controlbar .skip.right {
    width: auto;
  }
  .datetimedepthselector .controlbar .depthtimestep img.clock {
    height: 30px;
  }
}

@media print {
  .datetimedepthselector {
    display: none;
  }
}
