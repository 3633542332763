ul.maplayers-list {
  list-style-type: none;
  margin: 0;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  height: calc(100% - 330px);
  box-shadow: 1px 1px 10px #c4bbbb inset;
  border-radius: 5px;
  overflow-y: auto;
}

.maplayers-list li {
  list-style-type: none;
  z-index: 99999;
}

.maplayers-contents {
  margin-bottom: 15px;
}

.maplayers-dropdown {
  margin: 5px 0 20px 0;
}

.maplayers-dropdown-title {
  font-size: 16px;
}

.maplayers-dropdown-content {
  margin-left: 20px;
  margin-top: 5px;
}

.maplayers-dropdown .maplayers-dropdown-table {
  width: 100%;
  background-color: rgb(249, 249, 249);
  padding: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  box-sizing: border-box;
}

.maplayers-dropdown .maplayers-check {
  width: 10px;
  vertical-align: top;
}

.maplayers-dropdown .maplayers-checkbox {
  display: inline-block;
}

.maplayers-dropdown .maplayers-symbol {
  display: inline-block;
  cursor: pointer;
}

.maplayers-dropdown .maplayers-settings {
  cursor: pointer;
  font-size: 16px;
}

.maplayers-dropdown .maplayers-settings img {
  height: 16px;
  cursor: pointer;
  opacity: 0.7;
}

.maplayers-dropdown .maplayers-settings img.grey {
  opacity: 1;
}

.maplayers-dropdown .maplayers-settings img:hover {
  opacity: 1;
}

.maplayers-dropdown-content.hide {
  display: none;
}

.maplayers-dropdown-settings.hide {
  display: none;
}

.maplayers-parameter {
  font-size: 14px;
  font-style: italic;
}

.maplayers-label {
  font-size: 18px;
}

.maplayers-label .maplayers-arrow {
  font-size: 30px;
  width: 100%;
  text-align: center;
}

.editsettings {
  background-color: white;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.editsettings-title {
  margin: 10px 0 10px 4px;
  font-size: 16px;
  color: rgb(16, 42, 67);
  font-weight: 700;
}

.editsettings-table {
  table-layout: fixed;
}

.editsettings-table input[type="text"] {
  width: 50px;
}

.editsettings-table select {
  width: 80px;
}

.editsettings-button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.editsettings-button button {
  margin: 6px 3px;
  border-radius: 16px;
  color: #38bec9;
  border-color: #38bec9;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.editsettings-button button:hover {
  color: white;
  background-color: #38bec9;
}

.editsettings-button button:active {
  transform: scale(1.1);
}

.editsettings-button button.update-plot {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border: none;
  width: auto;
  margin: 10px;
}

.editsettings-button button.delete-layer {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  color: rgb(221, 17, 68);
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-color: rgb(221, 17, 68);
  width: auto;
  margin: 10px;
}

.editsettings-button button.delete-layer:hover {
  color: white;
  background-color: rgb(221, 17, 68);
}

.editsettings-button button.delete-layer:active {
  transform: none;
}

.editsettings-button button.update-plot:hover {
  transform: scale(1.05);
}

.editsettings table.min-max {
  table-layout: fixed;
  width: 100%;
}

.editsettings table.min-max input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid black;
  padding: 2px 10px;
  box-sizing: border-box;
}

@media screen and (max-height: 800px) {
  ul.maplayers-list {
    height: calc(100% - 290px);
  }
}

@media screen and (max-width: 500px) {
  ul.maplayers-list {
    height: calc(100% - 220px);
    padding-bottom: 65px;
    box-sizing: border-box;
  }
}
