/* -------------- Header -------------------------  */

header {
  position: fixed;
  background-color: white;
  margin-top: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  border-bottom: solid 10px #f9f9f9;
}

@media screen and (max-width: 500px) {
  header {
    box-shadow: none;
  }
}

header .header-inner {
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
}

/* Logo */

header .logo {
  display: inline-block;
  height: 22px;
  padding-top: 8px;
  margin-left: 20px;
  opacity: 0.4;
}

header .logo:hover {
  opacity: 1;
  transition: opacity 200ms linear;
}

header .logo img {
  height: 100%;
}

@media screen and (max-width: 500px) {
  header .logo {
    height: 20px;
  }
}

/* Mobile Navigation */

header .mobile-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999999;
  text-align: center;
  border-top: 1px solid #d8d6d3;
}

header .mobile-nav .mobile-navbar {
  width: 100%;
}

header .mobile-nav a {
  color: black;
  padding: 0 10px;
  text-decoration: none;
}

header .mobile-nav img {
  width: 60px;
  margin: 5px 2px;
}

header .mobile-nav .imgactive {
  background-color: rgb(249, 249, 249);
}

header .mobile-nav .imgactive img {
  border-bottom: 1px black solid;
}

header .mobile-nav .more {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
}

header .mobile-nav .mobile-flex {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

header .mobile-nav .mobile-menu {
  display: none;
  width: 100%;
}

header .mobile-nav .mobile-menu.show {
  display: block;
}

@media screen and (max-width: 500px) {
  header .mobile-nav {
    display: block;
  }
}

/* Navigation Menu */

header .desktop-nav {
  float: right;
}

header .desktop-nav .desktop-navbar .menu-icon {
  display: inline-block;
  cursor: pointer;
  padding-left: 40px;
  font-size: 16px;
  font-family: The Serif Bold, serif;
}

header .desktop-nav .desktop-navbar .menu-icon .symbol {
  display: inline-block;
  transform: rotate(90deg);
}

header .desktop-nav .desktop-navbar .links {
  display: inline-block;
}

header .desktop-nav .desktop-navbar .links img {
  height: 16px;
  margin-right: 15px;
  opacity: 0.5;
  padding-bottom: 4px;
  vertical-align: middle;
}

header .desktop-nav a.header-item {
  padding: 12px 50px 8px;
  color: #a29e9e;
  text-decoration: none;
  font-size: 16px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  display: inline-block;
  font-weight: 700;
}

header .desktop-nav a.header-item.active {
  color: #102a43;
  background-color: rgb(249, 249, 249);
}

header .desktop-nav a.header-item.active img {
  opacity: 1;
}

header .desktop-nav a.header-item:hover img {
  opacity: 1;
}

header .desktop-nav a.header-item:hover {
  color: black;
  background-color: rgb(249, 249, 249);
  transition: background-color 200ms linear;
  transition: color 200ms linear;
}

header .desktop-nav .desktop-menu {
  display: none;
  max-width: 500px;
  position: absolute;
  background-color: white;
}

header .desktop-nav .desktop-navbar {
  text-align: right;
}

header .desktop-nav .desktop-menu.show {
  display: block;
}

header .desktop-nav .midscreen {
  display: none;
}

@media screen and (max-width: 1100px) {
  header .desktop-nav a.header-item {
    padding: 9px 20px 9px 20px;
  }
}

@media screen and (max-width: 800px) {
  header .desktop-nav a.header-item {
    padding: 8px 10px;
    font-size: 16px;
  }
  header .desktop-nav .desktop-navbar .links img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  header .desktop-nav {
    display: none;
  }
}
