.linegraph-main {
  height: 100%;
  width: 100%;
}

.linegraph-main .linegraph-header {
  width: 100%;
  height: 30px;
}

.linegraph-main .linegraph-graph {
  position: relative;
  height: calc(100% - 30px);
}

.linegraph-main .linegraph-graph.full {
  position: relative;
  height: 100%;
}

.linegraph-graph {
  width: 100%;
  height: 100%;
}

.linegraph-graph .tooltip {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 7px 29px 0 rgb(100 100 111 / 30%);
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -ms-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;
  padding: 5px 10px;
  font-size: 12px;
}

.linegraph-graph .tooltip:after {
  content: " ";
  position: absolute;
  top: 10px;
  border-top: 10px solid transparent;
  box-shadow: 0 7px 29px 0 rgb(100 100 111 / 30%);
  border-bottom: 10px solid transparent;
}

.linegraph-graph .tooltip.tooltip-right:after {
  right: -10px;
  border-left: 10px solid rgba(255, 255, 255, 0.9);
  border-right: none;
}

.linegraph-graph .tooltip.tooltip-left:after {
  left: -10px;
  border-right: 10px solid rgba(255, 255, 255, 0.9);
  border-left: none;
}

.linegraph-graph .tooltip tr {
  height: 1px;
}

.linegraph-graph .linegraph-legend {
  position: absolute;
  right: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.linegraph-main table.vis-data {
  text-align: center;
  margin-left: 10px;
  margin-top: 15px;
  background-color: white;
  pointer-events: none;
  font-size: 12px;
  position: absolute;
  right: 25px;
}

.linegraph-main table.vis-data td {
  max-width: 100px;
}
