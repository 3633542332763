.monitor table {
  width: 100%;
}

.monitor .dataset {
  width: 150px;
  height: 150px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 15px;
  box-sizing: border-box;
  max-width: 30%;
  border: 3px solid;
}

.monitor .dataset .title {
  padding: 12px;
  height: 80px;
}

.monitor .dataset .inner {
  padding: 0 18px;
  font-size: 12px;
  font-weight: bold;
}

.debugging {
  padding: 25px 25px 35px 25px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  margin: 10px;
  box-sizing: border-box;
  margin-top: 100px;
}
