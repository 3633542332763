@import url(https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@100;500&display=swap);
.box-loader {
  width: 48px;
  height: 48px;
  position: relative;
  margin: auto;
  margin-bottom: 20px;
}
.box-loader::after,
.box-loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 2px solid #102a43;
  position: absolute;
  left: 0;
  top: 0;
  animation: rotationBreak 3s ease-in-out infinite alternate;
}
.box-loader::after {
  border-color: #38bec9;
  animation-direction: alternate-reverse;
}

@keyframes rotationBreak {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
    
.errorboundary {
  padding: 20% 10px 10px 10px;
  font-size: 60px;
}

.errorboundary img {
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.errorboundary .error-inner {
  font-size: 30px;
  color: grey;
}

/* -------------- Header -------------------------  */

header {
  position: fixed;
  background-color: white;
  margin-top: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  border-bottom: solid 10px #f9f9f9;
}

@media screen and (max-width: 500px) {
  header {
    box-shadow: none;
  }
}

header .header-inner {
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
}

/* Logo */

header .logo {
  display: inline-block;
  height: 22px;
  padding-top: 8px;
  margin-left: 20px;
  opacity: 0.4;
}

header .logo:hover {
  opacity: 1;
  transition: opacity 200ms linear;
}

header .logo img {
  height: 100%;
}

@media screen and (max-width: 500px) {
  header .logo {
    height: 20px;
  }
}

/* Mobile Navigation */

header .mobile-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999999;
  text-align: center;
  border-top: 1px solid #d8d6d3;
}

header .mobile-nav .mobile-navbar {
  width: 100%;
}

header .mobile-nav a {
  color: black;
  padding: 0 10px;
  text-decoration: none;
}

header .mobile-nav img {
  width: 60px;
  margin: 5px 2px;
}

header .mobile-nav .imgactive {
  background-color: rgb(249, 249, 249);
}

header .mobile-nav .imgactive img {
  border-bottom: 1px black solid;
}

header .mobile-nav .more {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
}

header .mobile-nav .mobile-flex {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

header .mobile-nav .mobile-menu {
  display: none;
  width: 100%;
}

header .mobile-nav .mobile-menu.show {
  display: block;
}

@media screen and (max-width: 500px) {
  header .mobile-nav {
    display: block;
  }
}

/* Navigation Menu */

header .desktop-nav {
  float: right;
}

header .desktop-nav .desktop-navbar .menu-icon {
  display: inline-block;
  cursor: pointer;
  padding-left: 40px;
  font-size: 16px;
  font-family: The Serif Bold, serif;
}

header .desktop-nav .desktop-navbar .menu-icon .symbol {
  display: inline-block;
  transform: rotate(90deg);
}

header .desktop-nav .desktop-navbar .links {
  display: inline-block;
}

header .desktop-nav .desktop-navbar .links img {
  height: 16px;
  margin-right: 15px;
  opacity: 0.5;
  padding-bottom: 4px;
  vertical-align: middle;
}

header .desktop-nav a.header-item {
  padding: 12px 50px 8px;
  color: #a29e9e;
  text-decoration: none;
  font-size: 16px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  display: inline-block;
  font-weight: 700;
}

header .desktop-nav a.header-item.active {
  color: #102a43;
  background-color: rgb(249, 249, 249);
}

header .desktop-nav a.header-item.active img {
  opacity: 1;
}

header .desktop-nav a.header-item:hover img {
  opacity: 1;
}

header .desktop-nav a.header-item:hover {
  color: black;
  background-color: rgb(249, 249, 249);
  transition: background-color 200ms linear;
  transition: color 200ms linear;
}

header .desktop-nav .desktop-menu {
  display: none;
  max-width: 500px;
  position: absolute;
  background-color: white;
}

header .desktop-nav .desktop-navbar {
  text-align: right;
}

header .desktop-nav .desktop-menu.show {
  display: block;
}

header .desktop-nav .midscreen {
  display: none;
}

@media screen and (max-width: 1100px) {
  header .desktop-nav a.header-item {
    padding: 9px 20px 9px 20px;
  }
}

@media screen and (max-width: 800px) {
  header .desktop-nav a.header-item {
    padding: 8px 10px;
    font-size: 16px;
  }
  header .desktop-nav .desktop-navbar .links img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  header .desktop-nav {
    display: none;
  }
}

/* -------------- Footer -------------------------  */

footer {
  border-top: 1px solid #d8d6d3;
  width: 960px;
  color: rgb(50, 50, 50);
  margin: auto;
  margin-top: 40px;
  max-width: 100%;
}

footer .container {
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

footer .container .partners {
  padding: 15px 0 10px 0;
  text-align: center;
}

footer .container .partners img {
  height: 30px;
  margin: 5px 3%;
}

footer .container .partners .partner-logo img {
  opacity: 0.5;
}

footer .container .partners .partner-logo img:hover {
  opacity: 1;
}

footer .container .copyright {
  width: 100%;
  text-align: right;
  box-sizing: border-box;
  padding: 0 50px 10px 0;
}

footer .container .copyright .contact {
  float: left;
  padding-left: 50px;
}

footer .container .copyright .contact img {
  height: 20px;
  margin-top: -4px;
  padding-right: 20px;
  opacity: 0.5;
}

footer .container .copyright .contact img:hover {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  footer {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  footer .container .partners img {
    width: 10%;
    height: auto;
  }
}

@media screen and (max-height: 820px) {
  footer {
    position: relative;
  }
}

.home {
  margin-top: -20px;
}

.home-banner {
  width: 100%;
  background: url(/static/media/banner.9aa07cc1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  padding-top: 25vh;
  text-align: center;
  margin-bottom: 30px;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.homepagemap {
  width: 100%;
  height: 700px;
  max-height: 70vh;
}

/* Chrome, Safari, Opera */

/* Standard syntax */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-banner .home-tagline {
  font-size: 60px;
  line-height: 1.05;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
}

.home-banner button {
  font-size: 22px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  animation: pulse 2s 5;
  border-radius: 15px;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.home-banner button:hover {
  transform: scale(1.05);
}

.home-partners {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}

.home-copyright {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 2px;
}

.home-partners img {
  height: 26px;
  margin: 5px 3%;
}

.home-partners .partner-logo .black {
  display: inline-block;
  opacity: 0.7;
}

.home-partners .partner-logo .color {
  display: none;
}

.home-partners .partner-logo:hover .black {
  display: none;
}

.home-partners .partner-logo:hover .color {
  display: inline-block;
}

.sectiontitle {
  width: 100%;
  margin-bottom: 120px;
  font-size: 36px;
  margin-top: 120px;
  color: rgb(134, 134, 139);
  font-family: "Arial", sans-serif;
  font-weight: bold;
  padding: 60px;
  box-sizing: border-box;
}

.sectiontitlemin {
  width: 100%;
  margin-bottom: 60px;
  font-size: 36px;
  margin-top: 120px;
  color: rgb(134, 134, 139);
  font-family: "Arial", sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.sectiontitle button {
  font-size: 18px;
  margin-right: 30px;
}

.sectiontitle button:hover {
  background-color: #e3e3e3;
}

.sectiontitle .sub {
  margin-top: 20px;
  color: black;
  font-size: 26px;
  margin-bottom: 10px;
}

.sectiontitle .scroll {
  font-size: 22px;
  margin: auto;
  margin-top: 30px;
}

.sectiontitle .arrow {
  transform: rotate(180deg);
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 20px;
  background-color: grey;
}

.sectiontitle .acc {
  margin-top: 15px;
}

.sectiontitle .letter {
  color: black;
  size: 50px;
  display: inline-block;
}

.sectiontitle .word {
  size: 14px;
  display: inline-block;
  padding-right: 10px;
}

.sectiontitle .lw {
  display: inline-block;
}

.section {
  position: relative;
}

.section.access {
  margin-top: -100px;
  margin-bottom: 100px;
}

.section.access a {
  text-decoration: none;
}

.section.access .box {
  width: 278px;
  border-radius: 20px;
  background-color: white;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  margin: 0 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(59, 65, 81, 0.3);
}

.section.access .box .box-header {
  background-color: #102a43;
  text-align: center;
  border-radius: 20px 20px 0 0;
  padding: 10px 0;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: white;
}

.section.access .box .box-img {
  padding: 40px 0;
  text-align: center;
}

.section.access .box .box-img img {
  height: 150px;
}

.section.access .box .box-text {
  padding: 0px 20px 20px 20px;
}

.section.access .box:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1000px) and (min-width: 650px) {
  .section.access .box {
    display: block;
    width: calc(100% - 100px);
    margin: 30px 50px;
  }
  .section.access .box .box-text {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;
    margin-top: 40px;
  }
  .section.access .box .box-img {
    display: inline-block;
    width: 50%;
    padding: 20px 0;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 650px) {
  .section.access {
    margin: 0;
  }
  .section.access .box {
    display: block;
    width: calc(100% - 60px);
    margin: 30px 30px;
  }
}

.section.lakesimulations {
  margin-bottom: 120px;
}

.section.lakesimulations img {
  width: 100%;
}

.section.lakesimulations .textbox {
  position: absolute;
  top: 0;
  right: 0;
}

.section.lakesimulations .outer {
  margin: 0;
}

.section.lakesimulations .textbox .straptext {
  text-align: right;
  font-size: 25px;
  margin-top: 30px;
  max-width: 712px;
  align-items: right;
  margin-bottom: 70px;
}

.section.lakesimulations .textbox .numberbox {
  display: inline-block;
  margin-left: 200px;
  font-size: 30px;
  text-align: center;
  color: grey;
}

.section.lakesimulations .textbox .numberbox .number {
  font-size: 90px;
  color: black;
}

.section.insitudata {
  margin-top: 50px;
}

.section.rs .img {
  width: 100%;
  background: url(/static/media/rsmap.20f04a3a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 70vh;
  display: flex;
  margin-bottom: 120px;
  color: white;
}

.section.insitudata .img {
  width: 100%;
  background: url(/static/media/lexplore.ef403542.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 70vh;
  display: flex;
  margin-bottom: 120px;
}

.section.insitudata .img table {
  width: 100%;
  height: 100%;
}

.section.lakesimulations .img {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  background: url(/static/media/zurich.0e124bf3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 120px;
  min-height: 70vh;
  margin-bottom: 120px;
  z-index: 999;
}

.section.insitudata .img table {
  font-size: 28px;
}

.section.lakesimulations .inner {
  float: right;
}

.section.lakesimulations a {
  text-decoration: none;
}

.section.lakesimulations button {
  display: block;
  font-size: 24px;
  border-radius: 15px;
  width: 300px;
  margin: auto;
  margin-top: 20px;
  background-color: white;
  color: black;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #bcccdc;
}

.section.lakesimulations button:hover {
  transform: scale(1.02);
}

.section.insitudata .inner {
  color: white;
}

.section .outer {
  font-size: 28px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  width: 960px;
  max-width: 100%;
  margin: auto;
  text-align: left;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.section .outer .inner {
  width: 50%;
  height: 100%;
}

.section.about {
  padding: 25px 50px;
  border-radius: 5px;
  background-color: white;
  font-size: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(59, 65, 81, 0.3);
}

.section.about .contacts .contact {
  display: inline-block;
  width: 50%;
}

.section.about .contacts .contact img {
  width: 40%;
  display: inline-block;
  border-radius: 20px;
}

.section.about .contacts .contact .text {
  display: inline-block;
  width: 60%;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: top;
  margin-top: 20px;
  font-size: 14px;
}

.section.about .title {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #102a43;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.section.insitudata button {
  border: none;
  color: white;
  display: block;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 30px;
  background-color: #102a43;
}

.section.insitudata button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.section.rs button {
  border: none;
  color: white;
  display: block;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 30px;
  background-color: #102a43;
}

.section.rs button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.section.insitudata a {
  text-decoration: none !important;
}

.section.rs a {
  text-decoration: none !important;
}

.section.insitudata .numberbox {
  display: inline-block;
  font-size: 30px;
  text-align: center;
  margin: 90px 0;
  color: white;
}

.section.insitudata .numberbox .number {
  font-size: 90px;
}

.section.rs {
  margin-top: 120px;
  margin-bottom: 120px;
  background: #f3f3f3;
}

.section.rs img {
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
}

.section.rs .text {
  font-size: 25px;
  margin: 60px 60px;
  padding: 40px 60px;
  border-bottom: 1px solid grey;
}

.triple {
  text-align: center;
  padding: 40px;
  background-color: #f3f3f3;
}

.triple-inner {
  width: 280px;
  display: inline-block;
  margin: 40px;
  vertical-align: top;
}

.triple-title {
  width: 100%;
  text-align: center;
  height: 40px;
  font-family: "The Serif Bold", Arial, sans-serif;
  font-size: 22px;
}

.triple-text {
  font-size: 18px;
  text-align: left;
}

.triple-img {
  text-align: center;
  height: 180px;
  vertical-align: middle;
  margin: 30px 0;
}

.triple-button {
  width: 100%;
  text-align: center;
}

.triple-button a button:hover {
  background-color: #d9d6d6;
}

.triple-img img {
  max-width: 100%;
  max-height: 100%;
}

.home-text .contact img {
  width: 150px;
}

@media screen and (max-width: 1300px) {
  .section.insitudata .numberbox {
    margin: 50px 0;
  }
}

@media screen and (max-width: 1050px) {
  .section.insitudata .title {
    font-size: 28px;
  }

  .section.insitudata .numberbox {
    margin: 20px 0;
  }

  .section.insitudata .numberbox .number {
    font-size: 70px;
  }
}

@media screen and (max-width: 960px) {
  .home-banner .home-tagline {
    font-size: 45px;
  }
  .home {
    padding: 0 20px;
  }
  .section.insitudata .title {
    font-size: 22px;
    margin-top: 20px;
  }

  .section.insitudata .numberbox {
    margin: 10px 0;
    font-size: 22px;
  }

  .section.insitudata .numberbox .number {
    font-size: 50px;
  }

  .section.lakesimulations .textbox .numberbox {
    margin-left: 50px;
    float: right;
  }
}

@media screen and (max-width: 500px) {
  .home {
    margin-top: 20px;
    padding: 0;
    margin-bottom: 100px;
  }
  .section.about .contacts .contact {
    width: 100%;
  }
  .section.about {
    box-sizing: border-box;
    width: 90%;
    margin-left: 5%;
    padding: 20px;
  }
  .home-banner {
    margin-bottom: 0;
    padding-top: 25vh;
  }
  .home-banner .home-tagline {
    font-size: 40px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .home-banner button {
    font-size: 16px;
  }

  .triple-inner {
    margin: 0;
  }

  .section.about .contacts .contact .text {
    padding: 0 10px;
    margin: 0;
  }

  .section.about .contacts .contact .text b {
    font-size: 12px;
  }

  .sectiontitle {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 25px;
  }

  .sectiontitle .sub {
    font-size: 18px;
  }

  .sectiontitle .scroll {
    font-size: 16px;
  }

  .sectiontitle .arrow {
    height: 20px;
    width: 20px;
  }

  .section.lakesimulations .textbox .straptext {
    font-size: 14px;
    width: 70%;
    float: right;
    padding-right: 20px;
  }
  .section.lakesimulations .textbox .numberbox {
    margin: 0 20px;
  }

  .section.lakesimulations .outer {
    font-size: 18px;
  }

  .section.lakesimulations .inner {
    width: 100%;
  }

  .section.rs .inner {
    width: 100%;
  }

  .section.insitudata {
    margin: 0;
  }

  .section.rs {
    margin: 0;
    margin-top: 20px;
  }

  .section.rs .text {
    padding: 20px;
    margin: 0;
    font-size: 18px;
  }

  .home-text {
    padding: 0 10px;
  }

  .home-text .contact img {
    width: 80px;
  }

  .home-copyright {
    margin-bottom: 65px;
  }
}

.notfound {
    padding-top: 100px;
    padding-bottom: 100px;
}

.url {
    display: inline-block;
    font-weight: 700;
}

.number-error {
    font-size: 200px;
}
/* -------------- Side Bar -------------------------  */

.sidebarlayout.wide {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding-left: 30px;
}

/* Right Side */

.sidebarlayout .rightcontainer {
  width: 318px;
  max-width: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  background-color: white;
  min-height: 500px;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.sidebarlayout .rightcontainer.hide {
  width: 40px;
}

.sidebarlayout .rightcontainer .righthead {
  cursor: pointer;
  border-bottom: 1px solid #c8c6bf;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  font-family: "The Serif Bold", Arial, sans-serif;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
}

.sidebarlayout .rightcontainer .righthead:hover {
  background-color: hsl(0, 0%, 95%);
}

.sidebarlayout .rightcontainer.hide .righthead {
  padding: 10px 8px;
  background-color: transparent;
  border: none;
  min-height: 500px;
}

.sidebarlayout .rightcontainer .righthead .sidebartitle {
  display: inline-block;
}

.sidebarlayout .rightcontainer .righthead span {
  float: right;
  font-size: 22px;
}

.sidebarlayout .rightcontainer .rightcontent {
  max-width: 300px;
  margin: auto;
  height: inherit;
  padding: 0 15px;
}

.sidebarlayout .rightcontainer .rightcontent .scroll {
  overflow: hidden;
  height: 458px;
}

.sidebarlayout .rightcontainer .rightcontent .scroll:hover {
  overflow-y: auto;
}

.sidebarlayout .rightcontainer.hide .righthead {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
}

.sidebarlayout .rightcontainer.hide .rightcontent {
  display: none;
}

.sidebarlayout .rightcontainer.hide .sidebartitle {
  padding-bottom: 10px;
}

.sidebarlayout .rightcontainer.hide span {
  transform: rotate(90deg);
  margin-bottom: 10px;
}

/* Left Side */

.sidebarlayout .leftcontainer {
  width: 638px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 30px;
  vertical-align: top;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

.sidebarlayout.wide .leftcontainer {
  width: calc(100vw - 390px);
}

.sidebarlayout .leftcontainer.full {
  width: calc(100% - 45px);
  padding-right: 15px;
}

.sidebarlayout.wide .leftcontainer.full {
  width: calc(100% - 110px);
  padding-right: 15px;
}

.extraspace {
  height: 20px;
}

/* Media Queries */

@media screen and (max-width: 960px) {
  .sidebarlayout .leftcontainer {
    width: calc(100vw - 323px);
  }
}

@media screen and (max-width: 500px) {
  .extraspace {
    height: 150px;
  }

  .sidebarlayout.wide {
    width: calc(100% - 10px);
    margin-left: 5px;
    padding: 0;
  }
  .sidebarlayout .rightcontainer.hide {
    width: 150px;
    min-height: 0;
    padding: 2px 5px;
    margin-bottom: 10px;
    margin-top: -10px;
    position: fixed;
    bottom: 65px;
    top: unset;
    left: unset;
    right: 5px;
    border-radius: 30px;
    z-index: 999;
  }

  .sidebarlayout .rightcontainer {
    left: 0;
    width: 100%;
    min-height: 0;
    order: 1;
    position: fixed;
    top: 41px;
    border-radius: 0;
    border: none;
    padding-bottom: 10px;
  }

  .sidebarlayout .rightcontainer.hide .righthead {
    -ms-writing-mode: unset;
    writing-mode: unset;
    min-height: unset;
    margin-bottom: 0;
    font-size: 15px;
  }

  .sidebarlayout .rightcontainer .righthead {
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 20px;
    padding: 20px;
  }

  .sidebarlayout .rightcontainer .righthead .sidebartitle {
    padding: 0;
    color: rgb(50, 50, 50);
  }

  .sidebarlayout .rightcontainer .righthead span {
    font-size: 35px;
  }

  .sidebarlayout .rightcontainer.hide .righthead span {
    transform: rotate(-90deg);
    font-size: 15px;
  }

  .sidebarlayout .rightcontainer .rightcontent .scroll {
    height: auto;
  }

  .sidebarlayout .rightcontainer .rightcontent {
    max-width: 500px;
    height: calc(100vh - 220px);
    overflow-y: auto;
    padding: 5px 20px 160px;
  }

  .sidebarlayout .leftcontainer {
    width: 100%;
    padding: 0;
    order: 2;
  }

  .sidebarlayout .leftcontainer.full {
    width: 100%;
    padding: 0;
  }

  .sidebarlayout.wide .leftcontainer.full {
    width: 100%;
    padding: 0;
  }

  .sidebarlayout {
    display: flex;
    flex-direction: column;
  }
}

.filterbox {
  padding: 16px 0px;
  border-bottom: 1px solid #c8c6bf;
}

.filterbox .toprow {
  cursor: pointer;
  line-height: 22px;
}

.filterbox .title {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #102a43;
}

.filterbox .new {
  display: inline-block;
  font-size: 12px;
  color: white;
  background-color: rgb(206, 56, 56);
  padding: 0 4px;
  margin-left: 2px;
}

.filterbox .symbol {
  float: right;
  font-size: 22px;
  transform: none !important;
}

.filterbox .content {
  padding-top: 10px;
}

.filterbox .content.hide {
  display: none;
}

.filterbox.inner {
  padding: 5px 0px;
  border: none;
}

.filterbox.inner .title {
  font-size: 14px;
}

.filterbox.inner .symbol {
  font-size: 20px;
}

/* Custom styling */

.leaflet-custom-icon:hover {
  width: 40px !important;
  height: 40px !important;
}

.leaflet-tooltip.gitTooltip {
  opacity: 1 !important;
  padding: 0;
  border-radius: 10px;
}

.leaflet-tooltip.gitTooltip .tooltip-date {
  background-color: #f2f5f7;
  color: #102a43;
  border-radius: 10px 10px 0 0;
  padding: 5px 10px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

.leaflet-tooltip .tooltip-small {
  font-size: 10px;
}

.leaflet-tooltip.basic-tooltip {
  color: #f2f5f7;
  background-color: #102a43;
  padding: 5px 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
}

.leaflet-tooltip.gitTooltip .tooltip-values {
  color: white;
  background-color: #102a43;
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.leaflet-tooltip.gitTooltip .tooltip-values .tooltip-value {
  display: inline-block;
  font-size: 22px;

}

.leaflet-tooltip.gitTooltip .tooltip-values .tooltip-unit {
  display: inline-block;
  margin-left: 3px;
}

.leaflet-tooltip.gitTooltip .tooltip-values .tooltip-param {
  font-size: 10px;
  margin-top: -4px;
  padding-bottom: 2px;
}

.leaflet-popup.datasetsPopup {
  margin-left: 19px;
}

.leaflet-popup.datasetsPopup .leaflet-popup-tip-container {
  margin-top: -1px;
  display: none;
}

.leaflet-popup.datasetsPopup .leaflet-container a.leaflet-popup-close-button {
  font-size: 18px;
  padding: 8px 8px 0 0;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper {
  background-color: transparent;
  box-shadow: none;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content {
  margin: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  color: #102a43;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-title {
  font-size: 20px;
  margin-bottom: 5px;
  padding: 10px 20px 0 20px;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-desc {
  padding: 0px 20px;
  margin-bottom: 5px;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-detail {
  text-align: center;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-detail
  button {
  padding: 6px 12px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: none;
  margin: 0;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-detail
  button:hover {
  transform: scale(1.01);
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-date {
  font-size: 16px;
  background-color: #f2f5f7;
  padding: 5px 0px;
  text-align: center;
  margin-top: 10px;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-buttons {
  background-color: #102a43;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-buttons
  button {
  color: white;
  border-radius: 10px;
  border: 1px solid white;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-buttons
  button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-values {
  background-color: #102a43;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  text-align: center;
}

.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-values
  .popup-value {
  color: white;
  font-size: 36px;
  display: inline-block;
}
.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-values
  .popup-unit {
  color: white;
  font-size: 16px;
  display: inline-block;
  margin-left: 3px;
}
.leaflet-popup.datasetsPopup
  .leaflet-popup-content-wrapper
  .popup-values
  .popup-param {
  color: white;
}

.leaflet-popup.datasetsPopup .leaflet-popup-content-wrapper .popup-layer {
  color: white;
  font-size: 18px;
  text-align: center;
}

/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
  height: 100%;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 400;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-control-container {
  z-index: 999;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  transform-origin: 0 0;
}
.leaflet-container.crosshair-cursor-enabled .leaflet-zoom-animated {
  pointer-events: none;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: grabbing;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078a8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAABQAAAAUBx99zUgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAALSURBVAiZY2AAAgAABQABYlUyiAAAAABJRU5ErkJggg==);
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAABQAAAAUBx99zUgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAALSURBVAiZY2AAAgAABQABYlUyiAAAAABJRU5ErkJggg==);
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAABQAAAAUBx99zUgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAALSURBVAiZY2AAAgAABQABYlUyiAAAAABJRU5ErkJggg==);
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution a {
  color: rgb(51, 51, 51) !important;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
  color: #333;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
}
.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 7px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 26px/24px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  transform: scale(1);
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 1px 5px;
  font-size: 14px;
  background-color: #fff;
  /*border: 1px solid #fff;*/
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  /* bottom: 0;*/
  /*margin-bottom: -12px;
	border-top-color: #fff;*/
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

@media print {
  .leaflet-control-container {
    display: none;
  }
}

 
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}
.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}
.map-marker .circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.map-marker .square {
  height: 10px;
  width: 10px;
}

.map-marker .pin {
  margin-left: -5px;
  margin-top: -23px;
  border-radius: 50% 50% 50% 0;
  border: 4px solid transparent;
  width: 12px;
  height: 15px;
  transform: rotate(-45deg);
}

.map-marker .pin::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -6.5px;
  background-color: #fff;
}

.map-marker .marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 26px;
  height: 26px;
  background: red;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -38px 0 0 -15px;
  box-shadow: -1px 1px 4px rgb(0 0 0 / 50%);
}

.map-marker .marker:after {
  content: "";
  margin: 8px 0 0 7px;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.mapselect {
  width: 100%;
  height: calc(100vh - 370px);
  min-height: 500px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 5px;
  border: solid 1px rgb(169, 169, 169);
}


.leaflet-draw {
  display: none;
}

.leaflet-draw-tooltip {
  display: none;
}

.closemap {
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  border-radius: 4px;
  font-size: 20px;
}

.closemap:hover {
  color: grey;
}

.dataset {
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-collapse: collapse;
}

.dataset:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dataset .dataset-top {
  padding: 20px 35px 20px 20px;
  position: relative;
  vertical-align: top;
}

.dataset .dataset-top .dataset-live {
  font-size: 10px;
  color: white;
  background-color: #14919b;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.dataset .dataset-top .buttons {
}

.dataset .dataset-top .buttons .button-main {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

.dataset .dataset-top .buttons .button-main:hover {
  transform: scale(1.05);
}

.dataset .dataset-top .buttons .button-sub {
  cursor: pointer;
  border: 1px solid #38bec9;
  display: inline-block;
  padding: 6px 16px;
  border-radius: 16px;
  margin-left: 16px;
  color: #38bec9;
  width: 90px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
}

.dataset .dataset-top .buttons .button-sub.grey {
  color: grey;
  border-color: grey;
}

.dataset .dataset-bottom {
  background-color: #f2f5f7;
  padding: 20px;
  color: #102a43;
  position: relative;
}

.dataset .dataset-bottom img.icon {
  height: 18px;
  margin-right: 8px;
  margin-bottom: -2px;
}

.dataset .dataset-bottom img.type {
  height: 46px;
  position: absolute;
  right: 12px;
  top: 18px;
}

.dataset .dataset-bottom .date-highlight {
  margin-top: 5px;
  max-width: calc(100% - 40px);
}

.dataset .dataset-top .dataset-title {
  font-size: 26px;
  margin-bottom: 10px;
}

.dataset .dataset-right {
  width: 160px;
  background-color: #102a43;
  vertical-align: top;
  padding: 20px 20px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.9);
}

.dataset .dataset-right .params {
  padding: 3px 0;
}

.search {
  position: relative;
}

.search img {
  position: absolute;
  left: 20px;
  top: 17px;
}

input.SearchBar {
  width: calc(100% - 10px);
  border: solid 1px rgb(169, 169, 169);
  box-sizing: border-box;
  padding: 6px 15px 6px 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  margin-left: 5px;
}

input.SearchBar:-ms-input-placeholder {
  font-size: 14px;
}

input.SearchBar::placeholder {
  font-size: 14px;
}

.download-selected .download-item {
  padding: 10px 20px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px 0;
  font-weight: 700;
}

.download-inner button {
  display: inline-block;
  border: none;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
}

table.time-selector {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  margin-top: 20px;
  margin-bottom: 10px;
}

table.time-selector input {
  width: 90%;
}

.download-multiple-title {
  font-size: 16px;
  font-weight: 700;
}

.selected-data-list {
  max-height: 60px;
  overflow: auto;
}

.MultipleDownload button {
  border: solid 1px black;
  border-radius: 5px;
  background-color: white;
  margin-right: 15px;
  margin-top: 5px;
  cursor: pointer;
  padding: 4px 8px;
  width: 120px;
}

.MultipleDownload button:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.download-container {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: none;
}

.download-container.show {
  display: block;
}

.datalist {
  display: none;
  margin-bottom: 120px;
}

.datalist.show {
  display: block;
}

.download-inner {
  padding: 5px 15px;
}

.download-close {
  width: 25px;
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: 700;
  font-size: 40px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.download-title {
  display: inline-block;
}

.download-top span {
  float: right;
  font-size: 18px;
}

.download-top {
  cursor: pointer;
}

.download-top h3 {
  border-bottom: 1px solid #c8c6bf;
  padding: 0 0 5px;
  margin: 0 0 16px;
  line-height: 20px;
}

.download-content {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.multi-select {
  padding: 5px 0;
}

.inner__control {
  border-color: black !important;
  cursor: text !important;
}

.inner__control:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.inner__indicator.inner__dropdown-indicator {
  color: black !important;
}

.inner__indicator.inner__clear-indicator {
  cursor: pointer;
}

.sortbar {
  width: 100%;
  display: inline-block;
}

.sortbar select {
  display: inline-block;
  background-color: white;
  margin-left: 5px;
  float: right;
  margin: 2px;
  border-radius: 5px;
}

table.sortbar-table {
  display: inline-block;
  cursor: pointer;
}

table.sortbar-table td {
  padding: 3px 7px;
  border: solid 1px rgb(169, 169, 169);
  font-size: 13.333px;
  background-color: white;
  border-radius: 5px;
}

.characteristics {
  margin-top: 10px;
  border-bottom: 1px solid #c8c6bf;
  padding-bottom: 16px;
}

/* Filter Bar */

.filterbar {
  margin-top: 5px;
}

.filterbar .filterbar-item {
  padding: 2px 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  border: 1px solid rgb(169, 169, 169);
  margin: 3px 8px 3px 0px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
}

.filterbar .filterbar-text {
  display: inline-block;
}

.filterbar .filterbar-x {
  display: inline-block;
  margin-left: 6px;
}

.filterboxinner {
  cursor: pointer;
}

.filterboxinner:hover {
  text-decoration: underline;
}

.popup {
  width: 100%;
  padding-right: 1px;
  border: 1px solid rgb(169, 169, 169);
  box-sizing: border-box;
  display: block;
  margin-bottom: 15px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.mapselecttitle {
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.hidepopup {
  width: 100%;
  height: 502px;
  display: none;
}

.dataportal-loading {
  text-align: center;
  margin-top: 100px;
}

.dataportal-message {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 500;
  padding: 40px 30px;
  background-color: white;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

@media screen and (max-width: 800px) {
  .dataset .innerdatasetleft {
    width: 100%;
  }

  .dataset .innerdatasetright {
    width: 100%;
  }

  .dataset .innerdatasetright .parameters-highlight {
    border: none;
    padding-left: 0;
    padding-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .sortbar select {
    padding: 4px 0;
  }
  table.sortbar-table td {
    padding: 6px 4px;
  }
  .checkbox {
    width: 14px;
    height: 14px;
  }
  .datalist {
    padding: 0 10px;
  }
  .sortbar {
    padding: 0 4px;
    box-sizing: border-box;
  }
  .filterbar {
    padding: 0 5px;
  }
  input.SearchBar {
    margin-bottom: 4px;
    margin-top: 2px;
  }
  .search img {
    left: 15px;
    top: 10px;
  }
  .dataset .dataset-top .dataset-title {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
  }
  .dataset .dataset-top .buttons .button-main {
    margin-top: 10px;
  }
  .dataset .dataset-top {
    padding: 20px 15px 15px 10px;
  }
  .dataset .dataset-right {
    width: 120px;
  }
  .dataset .dataset-top .buttons .button-sub {
    width: 60px;
    padding: 6px;
  }
}

.multi-steps > li.is-active:before,
.multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 700;
}
.multi-steps > li.is-active:after,
.multi-steps > li.is-active ~ li:after {
  background-color: #ededed;
}
.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0;
}
.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: black;
}
.multi-steps > li:before {
  content: "\f00c";
  content: "\2713";
  content: "\10003";
  content: "\10004";
  content: "\2713";
  display: block;
  margin: 0 auto 4px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  border-radius: 50%;
}
.multi-steps > li:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: black;
  position: absolute;
  top: 16px;
  left: 50%;
  z-index: -1;
}
.multi-steps > li:last-child:after {
  display: none;
}
.multi-steps > li.is-active:before {
  background-color: #fff;
  border-color: black;
}
.multi-steps > li.is-active ~ li {
  color: #808080;
}
.multi-steps > li.is-active ~ li:before {
  border-color: #ededed;
}

.buttonnav button {
  cursor: pointer;
  border: 1px solid #38bec9;
  display: inline-block;
  padding: 6px 24px;
  border-radius: 16px;
  margin-left: 16px;
  color: #38bec9;
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 20px 0 0;
}

.buttonnav button:hover {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.buttonnav {
  width: 100%;
  text-align: center;
  margin-bottom: 120px;
}

form.adddataset-form select {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: white;
}

#git {
  width: 850px;
  max-width: calc(100% - 20px);
  margin: auto;
  border-radius: 5px;
}

.adddataset-form .form-group {
  display: inline-block;
  text-align: center;
  margin-top: 30px;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
}

.adddataset-form .form-group label {
  display: block;
  color: grey;
  padding-bottom: 3px;
}

.adddataset-form .form-group label.optional-id {
  margin-top: 20px;
  font-size: 10px;
}

.adddataset-form .form-group .optional-id.hide {
  display: none;
}

.adddataset-form input {
  font-size: 14px;
  width: 100%;
  border: solid 1px rgb(169, 169, 169);
  box-sizing: border-box;
  padding: 7px 5px;
  margin-bottom: 0;
  background-color: white;
  text-align: center;
}

.adddataset-form input.optional-id {
  width: 80px;
}

.repo-connection {
  margin-top: 15px;
  margin-bottom: 30px;
}

table.datareview {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-bottom: 15px;
  border-spacing: 10px;
}

table.datareview th {
  padding: 5px 0;
  width: 16.66%;
  border-bottom: solid 1px black;
}

table.datareview th {
  padding: 5px 0;
  width: 16.66%;
  border-bottom: solid 1px black;
}

table.addmetadata {
  margin: auto;
}

table.addmetadata th {
  text-align: left;
}

table.parameter {
  width: 650px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 40px;
}

table.parameter th {
  padding: 5px 0;
  border-bottom: solid 1px black;
}

table.metadata {
  width: 650px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 40px;
  table-layout: fixed;
  overflow: hidden;
}

table.metadata th {
  width: 50%;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}

table.metadata td {
  width: 50%;
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
}

.input-lineage {
  margin: 30px 0;
}

.text-lineage {
  max-width: 60%;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 30px;
}

.lineage-text {
  width: 820px;
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.adddataset-form .welcome-text {
  width: 900px;
  max-width: 100%;
  margin: auto;
}

.adddataset-form .selectedfiles {
  width: 820px;
  max-width: 100%;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  margin-top: -1px;
}

.lineage-text .selectedfiles .sfile {
  padding: 2px 0;
}

.lineage-text li {
  list-style-type: decimal;
}

.container-fluid {
  margin-bottom: 50px;
  padding-top: 30px;
}

.error-message {
  margin: 20px 0;
  min-height: 80px;
  text-align: center;
  color: red;
}

.loading {
  color: black;
}

.dropdownadd {
  text-align: center;
}

.dropdownadd table {
  margin: auto;
}

.publish {
  text-align: center;
  margin-bottom: 150px;
}

.orderarrows div {
  cursor: pointer;
}

.fileconnection {
  margin-left: 20px;
}

.liveconnection {
  margin-left: 20px;
}

.adddataset-form .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: 10px;
  margin-right: 20px;
  vertical-align: middle;
}

.adddataset-form .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.adddataset-form .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.adddataset-form .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.adddataset-form input:checked + .slider {
  background-color: #38bec9;
}

.adddataset-form input:focus + .slider {
  box-shadow: 0 0 1px #38bec9;
}

.adddataset-form input:checked + .slider:before {
  transform: translateX(16px);
}

.adddataset-form .slider.round {
  border-radius: 34px;
}

.adddataset-form .slider.round:before {
  border-radius: 50%;
}

.dataselect__value-container {
  position: static !important;
}

.dataselect__indicator-separator {
  display: none !important;
}

.dataselect__single-value {
  color: black !important;  
}

.dataselect__dropdown-indicator {
  padding: 0 !important;
  padding-right: 8px !important;
}

.css-26l3qy-menu {
  width: auto!important;
  min-width: 100%!important;
}

.dataselect__single-value {
  max-width: calc(100% - 30px)!important;
}

.dataselect__control {
  border-color: rgb(169,169,169) !important;
  border-radius: 0 !important;
  cursor: text !important;
}

.dataselect__control:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.dataselect__indicator.dataselect__dropdown-indicator {
  color: rgb(169,169,169) !important;
}

.dataselect__indicator.dataselect__clear-indicator {
  cursor: pointer;
}

.multi-select {
  color: black;
}
.modal {
    position: fixed; 
    z-index: 999999999999;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }
  
  .modal-content {
    background-color: #fefefe;
    padding-bottom: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  .modal-content span {
    padding: 10px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
.fileselector {
  width: 820px;
  max-width: 100%;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid grey;
  padding: 10px 0;
  border-radius: 5px 5px 0 0;
  height: 250px;
  overflow: auto;
}

.fileselector img {
  width: 15px;
  padding-right: 10px;
}

.fileselector .file {
  margin: 4px 0 4px 15px;
}

.fileselector .folder {
  margin: 4px 0 4px 15px;
}

.fileselector .folder.selectable {
  cursor: pointer;
}

.fileselector .folder .dropdown {
  display: inline-block;
  font-size: 18px;
  padding-right: 10px;
  cursor: pointer;
}

.fileselector .file .file-checkbox {
  width: auto;
  margin-right: 10px;
}

.monitor table {
  width: 100%;
}

.monitor .dataset {
  width: 150px;
  height: 150px;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border-radius: 15px;
  box-sizing: border-box;
  max-width: 30%;
  border: 3px solid;
}

.monitor .dataset .title {
  padding: 12px;
  height: 80px;
}

.monitor .dataset .inner {
  padding: 0 18px;
  font-size: 12px;
  font-weight: bold;
}

.debugging {
  padding: 25px 25px 35px 25px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  margin: 10px;
  box-sizing: border-box;
  margin-top: 100px;
}

.morphology {
  position: fixed;
  width: 100%;
  height: calc(100% - 110px);
  bottom: 0;
  left: 0;
  background-color: rgb(249, 249, 249);
  z-index: 9;
  padding: 10px;
  box-sizing: border-box;
}

.morphology .left {
  width: calc(100% - 420px);
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.morphology .right {
  height: 100%;
  z-index: 999;
  background-color: white;
  width: 410px;
  padding: 20px 10px;
  box-sizing: border-box;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  position: relative;
}

.morphology .lakes-map {
  height: calc(100% - 510px);
  min-height: 300px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 999;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
}

.morphology .lakes-map #map {
  border-radius: 5px;
}

.morphology .lakes-map.full {
  height: calc(100% - 60px);
}

.morphology .lakes-map .morphology-loading {
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 250px;
}

.morphology .lake-morphology {
  height: 500px;
  width: 100%;
  background-color: white;
  padding: 5px 12px;
  box-sizing: border-box;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
}

.morphology .lake-morphology.short {
  height: 50px;
  padding: 10px;
}

.morphology .lake-morphology .lakes-graph-short {
  width: 100%;
  height: calc(100% - 90px);
}

.morphology .lake-morphology .banner {
  text-align: center;
  font-size: 22px;
}

.morphology .right .lake-list-header {
  height: 85px;
}

.morphology .right .lake-list-header input {
  width: calc(100% - 5px);
  border: solid 1px rgb(169, 169, 169);
  box-sizing: border-box;
  padding: 6px 15px 6px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  margin-left: 5px;
}

.morphology .right .lake-list-header table th {
  cursor: pointer;
  border-bottom: 1px solid white;
}

.morphology .right .lake-list-header table th:hover {
  border-bottom: 1px solid black;
}

.morphology .right .lake-list-body {
  height: calc(100% - 70px);
  overflow-y: auto;
}

.lake-list-body table {
  border-collapse: collapse;
}

.lake-list-body table tr {
  cursor: pointer;
}

.lake-list-body table tr:nth-child(even) {
  background: #f3f3f3;
}
.lake-list-body table tr:nth-child(odd) {
  background: #fff;
}

.lake-list-body table tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.lake-list-body table td {
  padding: 5px 10px;
}

.lake-list-body table td.center {
  text-align: center;
}

.lakes-map-short {
  width: 100%;
  height: 350px;
}

.lake-morphology .interpolated {
  display: inline-block;
}

.lake-morphology .xselect {
  text-align: center;
  display: inline-block;
  margin: auto;
  width: calc(100% - 20px);
}

.lake-morphology .reference {
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  margin-top: 5px;
}

.lake-morphology .xselect .text {
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}

.lake-morphology .xselect select {
  font-size: 16px;
  padding: 4px 6px;
  border-radius: 10px;
  background-color: white;
}

.lake-morphology .xselect button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.lake-morphology .xselect button:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .morphology .right {
    display: none;
  }
  .morphology .left {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .morphology {
    height: calc(100% - 150px);
    bottom: 70px;
    overflow-y: auto;
  }
  .lake-morphology {
    height: 400px;
  }
  .lakes-map {
    margin-bottom: 70px;
  }
  .morphology .lake-morphology.short {
    padding: 0 10px;
  }
  .morphology .lake-morphology .banner {
    font-size: 14px;
    padding: 6px;
  }
  .lake-morphology .xselect button {
    display: none;
  }
}

.overlay {
  fill: none;
  pointer-events: all;
}

#legend {
  position: absolute;
  top: 50px;
  left: 70px;
  background-color: white;
  padding: 3px;
  cursor: pointer;
}

.vis-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vis-main.full {
  position: fixed;
  left: 0;
  top: 40px;
  width: 100%;
  height: calc(100% - 40px);
  background-color: white;
  z-index: 9999999;
  padding: 20px;
  box-sizing: border-box;
}

.vis-header table.downloadtable {
  width: 100%;
  text-align: center;
}

.vis-header table.downloadtable td.title {
  text-decoration: underline;
}

.vis-header table td {
  position: relative;
  vertical-align: top;
}

.vis-header img {
  height: 18px;
  cursor: pointer;
}

.vis-header .downloadbar {
  position: absolute;
  top: 30px;
  right: -58px;
  z-index: 99;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 250px;
  box-sizing: border-box;
  cursor: auto;
}

.vis-header .downloadbar.hide {
  display: none;
}

.fontsize {
  position: relative;
}

.fontsize:hover .fontsize-dropdown {
  display: block;
}

.fontsize .fontsize-dropdown .sel {
  font-weight: bold;
}

.fontsize .fontsize-dropdown {
  display: none;
  position: absolute;
  top: 25px;
  left: 0px;
  width: 25px;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  padding: 4px 0;
  border-radius: 4px;
  z-index: 999999;
}
.fontsize .fontsize-dropdown div:hover {
  font-weight: bold;
}

.fontsize .fontsize-dropdown div {
  cursor: pointer;
  padding: 2px 0;
}

.graphhelpbar {
  font-size: 18px;
  cursor: pointer;
}

.graphhelpbar table {
  font-size: 14px;
  text-align: left;
}

.graphhelpbar:hover .graphhelp {
  display: block;
}

.graphhelp {
  z-index: 3;
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  width: 300px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  padding: 10px;
  max-width: 100vw;
  box-sizing: border-box;
}

.vis-header .downloadbar button {
  font-size: 10px;
  padding: 3px 5px;
  width: 50px;
  margin: 15px 10px 5px 10px;
  background-color: #fff;
  display: inline-block;
}

.vis-header .downloadbar button:hover {
  background-color: rgb(240, 240, 240);
}

.vis-header .downloadbar button.red {
  border-color: #fb0000;
  color: #fb0000;
}

.vis-header .downloadbar button.blue {
  border-color: #28b5f5;
  color: #28b5f5;
}

.linegraph-main {
  height: 100%;
  width: 100%;
}

.linegraph-main .linegraph-header {
  width: 100%;
  height: 30px;
}

.linegraph-main .linegraph-graph {
  position: relative;
  height: calc(100% - 30px);
}

.linegraph-main .linegraph-graph.full {
  position: relative;
  height: 100%;
}

.linegraph-graph {
  width: 100%;
  height: 100%;
}

.linegraph-graph .tooltip {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 7px 29px 0 rgb(100 100 111 / 30%);
  transition: all 0.15s;
  padding: 5px 10px;
  font-size: 12px;
}

.linegraph-graph .tooltip:after {
  content: " ";
  position: absolute;
  top: 10px;
  border-top: 10px solid transparent;
  box-shadow: 0 7px 29px 0 rgb(100 100 111 / 30%);
  border-bottom: 10px solid transparent;
}

.linegraph-graph .tooltip.tooltip-right:after {
  right: -10px;
  border-left: 10px solid rgba(255, 255, 255, 0.9);
  border-right: none;
}

.linegraph-graph .tooltip.tooltip-left:after {
  left: -10px;
  border-right: 10px solid rgba(255, 255, 255, 0.9);
  border-left: none;
}

.linegraph-graph .tooltip tr {
  height: 1px;
}

.linegraph-graph .linegraph-legend {
  position: absolute;
  right: 30px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.linegraph-main table.vis-data {
  text-align: center;
  margin-left: 10px;
  margin-top: 15px;
  background-color: white;
  pointer-events: none;
  font-size: 12px;
  position: absolute;
  right: 25px;
}

.linegraph-main table.vis-data td {
  max-width: 100px;
}

.shading {
  height: 250px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 990;
}

.datetimedepthselector {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 994;
  min-width: 300px;
}

.datetimedepthselector .timeselectionbar {
  padding: 0 50px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  cursor: pointer;
}

.datetimedepthselector:hover .timeselectionbar {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.datetimedepthselector .timeselectionbar .timeselector .xaxis {
  color: white;
  font-size: 14px;
}

.datetimedepthselector .controlbar {
  padding: 20px 50px;
}

.datetimedepthselector .controlbar .playpause {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 20px;
}

.datetimedepthselector .controlbar .playpause:hover {
  transform: scale(1.2);
}

.datetimedepthselector .controlbar .playpause img {
  height: 30px;
  margin-bottom: -4px;
}

.datetimedepthselector .controlbar .skip {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 20px;
  position: relative;
  margin: 0 20px;
}

.datetimedepthselector .controlbar .skip.right {
  width: 80px;
}

.datetimedepthselector .controlbar .skip .timestep-text.right {
  position: absolute;
  top: 12px;
  width: 100%;
  left: 10px;
  color: white;
  font-size: 18px;
}

.datetimedepthselector .controlbar .skip:hover {
  transform: scale(1.1);
}

.datetimedepthselector .controlbar .skip img {
  height: 35px;
  margin-bottom: -5px;
}

.datetimedepthselector .controlbar .datetime {
  display: inline-block;
  color: white;
  font-size: 25px;
  padding-bottom: 3px;
  width: calc(100% - 450px);
  text-align: center;
}

.datetimedepthselector .controlbar .datetime .text {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.datetimedepthselector .controlbar .datetime .text:hover {
  transform: scale(1.02);
}

.datetimedepthselector .controlbar .depthtimestep {
  color: white;
  font-size: 25px;
  padding-bottom: 3px;
  float: right;
}

.datetimedepthselector .controlbar .depthtimestep .text {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.datetimedepthselector .controlbar .depthtimestep .text:hover {
  transform: scale(1.1);
}

.datetimedepthselector .controlbar .depthtimestep img {
  height: 20px;
}

.datetimedepthselector .controlbar .depthtimestep img.clock {
  height: 28px;
  margin-bottom: -4px;
}

.selectormodal {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.selectormodal td.modalfull {
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.selectormodal .selectorbox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  color: white;
}

.selectormodal .selectorbox .closemodal {
  width: 100%;
  cursor: pointer;
}

.selectormodal .selectorbox .closemodal .icon {
  float: right;
  font-size: 28px;
}

.selectormodal .selectorbox .editor {
  padding: 30px;
  max-width: 650px;
}

.selectormodal .selectorbox .editor.time {
  font-size: 50px;
}

.selectormodal .selectorbox .editor td.modalarrow {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 25px;
}

.selectormodal .selectorbox .editor td.modalarrow:hover {
  transform: scale(1.1);
}

.selectormodal .selectorbox .editor.timestep {
  font-size: 16px;
  max-width: 350px;
}

.selectormodal .selectorbox .editor.timestep .timesteplist {
  display: flex;
  flex-flow: wrap;
  align-content: flex-start;
  transform-origin: top left;
  justify-content: center;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item {
  border: 1px solid white;
  padding: 8px 12px;
  margin: 10px;
  cursor: pointer;
  width: 70px;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item.selected {
  background-color: #006edc;
}

.selectormodal .selectorbox .editor.timestep .timesteplist .item:hover {
  background-color: #006edc;
}

.selectormodal .selectorbox .editor.depth input {
  color: white;
  background-color: transparent;
}

.editor .react-calendar {
  background-color: rgba(0, 0, 0, 0.4);
}

.editor .react-calendar__month-view__days {
  justify-content: center;
}

.editor .react-calendar button {
  color: white;
  border: none;
}

.editor .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #006edc;
}

.editor .react-calendar__navigation button:enabled:hover,
.editor .react-calendar__navigation button:enabled:focus {
  background-color: #76baff;
}

.depthselector {
  height: 70vh;
  width: 300px;
}

@media screen and (max-width: 1000px) {
  .datetimedepthselector .controlbar .depthtimestep {
    font-size: 18px;
  }
  .datetimedepthselector .controlbar .datetime {
    font-size: 22px;
    width: 150px;
  }
  .datetimedepthselector .controlbar .playpause img {
    height: 20px;
    margin-bottom: -2px;
  }
  .datetimedepthselector .controlbar .depthtimestep img {
    height: 15px;
  }
  .datetimedepthselector .controlbar {
    padding: 20px 20px;
  }
  .datetimedepthselector .controlbar .skip {
    margin: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .datetimedepthselector .controlbar .datetime {
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;
  }
  .datetimedepthselector .timeselectionbar {
    display: none;
  }
  .datetimedepthselector .controlbar .skip img {
    height: 35px;
  }
  .datetimedepthselector .controlbar .playpause {
    margin-right: 15px;
  }
  .datetimedepthselector .controlbar .playpause img {
    height: 35px;
    margin-bottom: -5px;
  }

  .datetimedepthselector .controlbar .depthtimestep img {
    height: 25px;
    margin-bottom: -5px;
  }

  .datetimedepthselector .controlbar .depthtimestep {
    padding-top: 3px;
  }

  .datetimedepthselector .controlbar .skip .timestep-text.right {
    display: none;
  }
  .datetimedepthselector .controlbar .skip.right {
    width: auto;
  }
  .datetimedepthselector .controlbar .depthtimestep img.clock {
    height: 30px;
  }
}

@media print {
  .datetimedepthselector {
    display: none;
  }
}

#depthselector {
  height: 100%;
  position: relative;
  text-align: left;
}

.tooltipdepth {
  position: absolute;
  z-index: 10;
  top: -110px;
  visibility: hidden;
  width: 250px;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  font-size: 12px;
  pointer-events: none;
  color: black;
  text-align: left;
}

.tooltipdepth:after {
  content: "";
  position: absolute;
  left: -25px;
  top: 20px;
  margin: 0 auto;
  width: 0;
  height: 0;
  z-index: 0;
  border-left: 25px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform: rotate(180deg);
}

.tooltipdepth .tooltip-title {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.timetooltip {
  position: absolute;
  z-index: 10;
  top: -110px;
  visibility: hidden;
  width: 250px;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  font-size: 12px;
  pointer-events: none;
}

.tooltip-info {
  position: absolute;
  top: -70px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
}

.timetooltip:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -25px;
  margin: 0 auto;
  width: 0;
  height: 0;
  z-index: 0;
  border-top: 25px solid white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  right: 0;
}

.timetooltip .tooltip-title {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.siderbar-datetime {
  width: 100%;
  text-align: center;
  color: #102a43;
  cursor: pointer;
  margin-bottom: 30px;
}

.siderbar-datetime table {
  margin: auto;
  transition: all 0.1s ease-in-out;
}

.siderbar-datetime table:hover {
  transform: scale(1.02);
}

.siderbar-datetime table td.day {
  font-size: 110px;
}

.siderbar-datetime table td.month {
  font-size: 40px;
  vertical-align: bottom;
}

.siderbar-datetime table td.year {
  font-size: 40px;
  vertical-align: top;
}

.siderbar-datetime .time {
  font-size: 30px;
  margin-top: -16px;
  transition: all 0.1s ease-in-out;
}

.siderbar-datetime .time:hover {
  transform: scale(1.02);
}

@media screen and (max-height: 800px) {
  .siderbar-datetime {
    margin-bottom: 10px;
    margin-top: -20px;
  }
  .siderbar-datetime table td.day {
    font-size: 80px;
  }
  .siderbar-datetime table td.month {
    font-size: 28px;
  }

  .siderbar-datetime table td.year {
    font-size: 28px;
  }

  .siderbar-datetime .time {
    font-size: 22px;
  }
}

@media screen and (max-width: 500px) {
  .siderbar-datetime {
    margin-bottom: 10px;
    margin-top: -20px;
  }
  .siderbar-datetime table td.day {
    font-size: 80px;
  }
  .siderbar-datetime table td.month {
    font-size: 28px;
  }

  .siderbar-datetime table td.year {
    font-size: 28px;
  }

  .siderbar-datetime .time {
    font-size: 22px;
  }
}

.layergroups .layergroups-content {
  max-height: calc(100vh - 457px);
  overflow-y: auto;
  background-color: white;
  box-shadow: 1px 1px 10px #c4bbbb inset;
  border-radius: 5px;
  padding-top: 15px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.layergroups .layergroups-content::-webkit-scrollbar {
  display: none;
}

.layergroups .layergroups-item {
  margin: 10px 8% 10px 8%;
  display: inline-block;
  width: 145px;
  vertical-align: top;
  max-width: 34%;
  cursor: pointer;
  text-align: center;
  color: #102a43;
}

.layergroups .layergroups-header {
  font-size: 18px;
  color: #102a43;
  text-align: center;
  margin-bottom: 25px;
}

.layergroups .layergroups-item img {
  width: 60%;
  margin-bottom: 10px;
}

.layergroups .layergroups-item img:hover {
  animation: shake 1s;
}

.layergroups .layergroups-welcome-message {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .layergroups .layergroups-welcome-message {
    font-size: 14px;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@media screen and (max-height: 800px) {
  .layergroups {
    max-height: calc(100vh - 250px);
  }
}

@media screen and (max-width: 500px) {
  .layergroups {
    max-height: calc(100vh - 310px);
    padding-bottom: 65px;
    box-sizing: border-box;
  }
}

ul.maplayers-list {
  list-style-type: none;
  margin: 0;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  height: calc(100% - 330px);
  box-shadow: 1px 1px 10px #c4bbbb inset;
  border-radius: 5px;
  overflow-y: auto;
}

.maplayers-list li {
  list-style-type: none;
  z-index: 99999;
}

.maplayers-contents {
  margin-bottom: 15px;
}

.maplayers-dropdown {
  margin: 5px 0 20px 0;
}

.maplayers-dropdown-title {
  font-size: 16px;
}

.maplayers-dropdown-content {
  margin-left: 20px;
  margin-top: 5px;
}

.maplayers-dropdown .maplayers-dropdown-table {
  width: 100%;
  background-color: rgb(249, 249, 249);
  padding: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  box-sizing: border-box;
}

.maplayers-dropdown .maplayers-check {
  width: 10px;
  vertical-align: top;
}

.maplayers-dropdown .maplayers-checkbox {
  display: inline-block;
}

.maplayers-dropdown .maplayers-symbol {
  display: inline-block;
  cursor: pointer;
}

.maplayers-dropdown .maplayers-settings {
  cursor: pointer;
  font-size: 16px;
}

.maplayers-dropdown .maplayers-settings img {
  height: 16px;
  cursor: pointer;
  opacity: 0.7;
}

.maplayers-dropdown .maplayers-settings img.grey {
  opacity: 1;
}

.maplayers-dropdown .maplayers-settings img:hover {
  opacity: 1;
}

.maplayers-dropdown-content.hide {
  display: none;
}

.maplayers-dropdown-settings.hide {
  display: none;
}

.maplayers-parameter {
  font-size: 14px;
  font-style: italic;
}

.maplayers-label {
  font-size: 18px;
}

.maplayers-label .maplayers-arrow {
  font-size: 30px;
  width: 100%;
  text-align: center;
}

.editsettings {
  background-color: white;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.editsettings-title {
  margin: 10px 0 10px 4px;
  font-size: 16px;
  color: rgb(16, 42, 67);
  font-weight: 700;
}

.editsettings-table {
  table-layout: fixed;
}

.editsettings-table input[type="text"] {
  width: 50px;
}

.editsettings-table select {
  width: 80px;
}

.editsettings-button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.editsettings-button button {
  margin: 6px 3px;
  border-radius: 16px;
  color: #38bec9;
  border-color: #38bec9;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.editsettings-button button:hover {
  color: white;
  background-color: #38bec9;
}

.editsettings-button button:active {
  transform: scale(1.1);
}

.editsettings-button button.update-plot {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border: none;
  width: auto;
  margin: 10px;
}

.editsettings-button button.delete-layer {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  color: rgb(221, 17, 68);
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-color: rgb(221, 17, 68);
  width: auto;
  margin: 10px;
}

.editsettings-button button.delete-layer:hover {
  color: white;
  background-color: rgb(221, 17, 68);
}

.editsettings-button button.delete-layer:active {
  transform: none;
}

.editsettings-button button.update-plot:hover {
  transform: scale(1.05);
}

.editsettings table.min-max {
  table-layout: fixed;
  width: 100%;
}

.editsettings table.min-max input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid black;
  padding: 2px 10px;
  box-sizing: border-box;
}

@media screen and (max-height: 800px) {
  ul.maplayers-list {
    height: calc(100% - 290px);
  }
}

@media screen and (max-width: 500px) {
  ul.maplayers-list {
    height: calc(100% - 220px);
    padding-bottom: 65px;
    box-sizing: border-box;
  }
}

.legend {
  z-index: 998;
  background-color: white;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 5px;
}

.legend.hide {
  width: auto;
}

.legend-inner {
  display: inline-block;
  max-width: 120px;
  margin: 2px 10px;
  text-align: center;
  vertical-align: top;
}

.legend-title {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #f2f5f7;
  border-radius: 5px 5px 0px 0px;
  font-weight: 700;
  text-align: center;
}

.legend.hide .legend-title {
  border: none;
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.legend-title-text {
  display: inline-block;
}

.legend-content {
  font-size: 12px;
  max-height: 350px;
  padding: 10px;
  overflow-y: auto;
}

.legend-content.hide {
  display: none;
}

.rasterdisplay-colorbar {
  width: 20px;
  height: 100%;
}

.rasterdisplay-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.markerdisplay-table {
  border-collapse: collapse;
  border-spacing: 0;
}

tr {
  height: 22px;
}

.rasterdisplay-innerlabel {
  font-size: 11px;
}

.rasterdisplay-bar {
  padding: 0;
  font-size: 8px;
}

.markerdisplay-symbol {
  line-height: 16px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .legend {
    max-width: calc(100% - 20px);
  }
}

.colormanipulation-outer {
  height: 100%;
  margin-bottom: 10px;
  background-color: rgb(249,249,249);
  border-radius: 5px;
}

.colormanipulation-headerbar {
  margin-bottom: 20px;
}

.colormanipulation-outer input[type="color"] {
  width: 100%;
}

.colormanipulation-header {
  display: inline-block;
  padding: 8px 18px;
  cursor: pointer;
}

.colormanipulation-header:hover {
  color: white;
  background-color: #38bec9;
}

.colormanipulation-header.header-active {
  color: white;
  background-color: #38bec9;
}

.colormanipulation-content {
  padding:5px
}

.colorramp {
  position: relative;
  display: inline-block;
  width: 100%;
}

.colorramp-option {
  width: 100%;
  height: 25px;
  text-align: center;
  color: transparent;
  line-height: 25px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 0 4px 0;
}

.colorramp-option:hover {
  color: black;
}

.colorramp-dropdown {
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  border: 1px solid grey;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(150, 144, 150, 0.6);
}

.colorramp-dropdown.hide {
  display: none;
}

.colorramp-select {
  width: 100%;
  height: 25px;
  cursor: pointer;
  text-align: right;
  padding: 0 6px;
  box-sizing: border-box;
  margin-bottom: 6px;
  border: 1px solid black;
  border-radius: 5px;
}

.colorramp-select .colorramp-arrow {
  transform: rotate(90deg);
  display: inline-block;
  font-size: 22px;
  color: black;
  height: 20px;
  font-family: "The Serif Bold", Arial, sans-serif;
  pointer-events: none;
}

.colorramp-dropdown::-webkit-scrollbar {
  display: none;
}

.colorramp-dropdown {
  -ms-overflow-style: none;
}

.colortable {
  height: calc(100% - 60px);
  overflow: auto;
}

.colortable table {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.colortable table button {
  cursor: pointer;
  border: 1px solid #38bec9;
  display: inline-block;
  padding: 6px 16px;
  border-radius: 16px;
  color: #38bec9;
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  margin: 10px 0;
}

.colortable table button:hover {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.colortable table input[type="text"] {
  border-radius: 16px;
  padding: 3px 10px;
  border: 1px solid black;
}

form.colortable table input {
  width: 100%;
}

form.colortable table input[type="color"] {
  padding: 0;
  border: none;
}

form.colortable table input[type="text"] {
  background-color: transparent;
  border: none;
  font-size: 14px;
}

.colortable-deleterow {
  cursor: pointer;
  text-align: center;
  font-size: 20px;
}

.colorslider-histogram {
    width: 100%;
    height: 120px;
}


.addlayers .filterbox.inner .title {
  font-size: 22px;
  padding: 10px 0;
}

.addlayers .addlayers-box {
  border: 1px solid grey;
}

.addlayers-titlebar {
  padding: 12px 10px;
  cursor: pointer;
}

.addlayers-titlebar:hover {
  background-color: rgba(222, 235, 255);
}

.addlayers-title {
  display: inline-block;
  max-width: calc(100% - 50px);
}

.addlayers-symbol {
  float: right;
  width: 50px;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
}

.addlayers-detail {
  cursor: pointer;
  position: relative;
  background-color: #f2f5f7;
  border-radius: 5px;
  padding: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  box-sizing: border-box;
  margin: 10px;
}

.addlayers-detail:hover {
  box-shadow: rgb(0 0 0 / 44%) 0px 3px 8px;
}

.addlayers-detail table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
}

.addlayers-detail table td {
  width: 33%;
}

.gis {
  width: 100%;
  height: 100%;
}

.gis .siderbar-mini.hide {
  visibility: hidden;
  position: absolute;
}

.gis .sidebar-inner.hide {
  visibility: hidden;
  position: absolute;
}

.gis .sidebar-buttons.hide {
  visibility: hidden;
  position: absolute;
}

.gis .sidebar {
  width: 400px;
  height: 100%;
  background-color: rgb(240, 240, 240);
  display: inline-block;
  position: relative;
  vertical-align: top;
  max-width: 100%;
  z-index: 999;
}

.gis .sidebar.min {
  width: 40px;
  cursor: pointer;
}

.gis .sidebar .sidebar-inner {
  width: 100%;
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
  height: 100%;
  background-color: rgb(240, 240, 240);
}

.gis .sidebar .sidebar-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 400px;
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: right;
  background-color: rgb(240, 240, 240);
  max-width: 100vw;
}

.gis .sidebar .sidebar-buttons button.addlayers {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border: none;
  margin: 0;
}

.gis .sidebar .sidebar-buttons button.addlayers:hover {
  transform: scale(1.05);
}

.gis .sidebar .sidebar-buttons button.hidemenu {
  cursor: pointer;
  border: 1px solid #38bec9;
  display: inline-block;
  padding: 6px 16px;
  border-radius: 16px;
  margin-left: 16px;
  color: #38bec9;
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 20px 0 0;
}

.gis .sidebar .sidebar-buttons button.hidemenu:hover {
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.gis .sidebar .boundary {
  height: 100%;
  background-image: linear-gradient(90deg, rgba(25, 25, 25, 0.4), transparent);
  width: 15px;
  position: absolute;
  right: -15px;
  pointer-events: none;
  z-index: 999;
}

.gis .siderbar-mini {
  cursor: pointer;
  font-size: 24px;
  color: #102a43;
  padding: 9px 0 0 9px;
}

.gis .siderbar-mini .rotate {
  transform: rotate(90deg);
  transform-origin: left;
  width: 500px;
  padding-bottom: 20px;
  font-size: 18px;
}

.gis .map {
  height: 100%;
  width: calc(100% - 400px);
  display: inline-block;
  min-height: 300px;
  position: relative;
}

.gis .map .map-loading {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 100%;
}

.gis .map .map-loading .map-loading-inner {
  top: calc(50% - 76px);
  position: absolute;
  width: 100%;
}

.gis .map.min {
  width: calc(100% - 40px);
}

.gis .layers {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.gis .layers.hide {
  visibility: hidden;
}

.gis .layers .layers-modal {
  width: 700px;
  margin: auto;
  margin-top: 15vh;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  max-width: 100%;
  max-width: 90%;
}

.gis .layers .layers-modal .layers-modal-header {
  background-color: #f2f5f7;
  padding: 20px 30px;
  border-radius: 5px 5px 0 0;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: #102a43;
  position: relative;
}

.gis .layers .layers-modal .layers-modal-content {
  padding: 20px;
  font-size: 16px;
  max-height: 70vh;
  overflow-y: auto;
}

.gis .react-calendar {
  margin: auto;
  border: none;
}

.gis .timedepth {
  font-size: 22px;
  text-align: center;
}

.gis .timedepth .input-depth {
  font-size: 50px;
  width: 150px;
  text-align: right;
  border: none;
  margin: 10px 0;
}

.gis .timedepth .input-time {
  font-size: 34px;
  border: none;
  margin: 10px 0;
}

.gis .timedepth button {
  display: block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border: none;
  margin: auto;
  margin-top: 30px;
}

.gis .timedepth button:hover {
  transform: scale(1.05);
}

.gis .gis-slider {
  font-size: 18px;
  color: #102a43;
  margin-top: 12px;
}

.gis .gis-slider .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: 10px;
}

.gis .gis-slider .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.gis .gis-slider .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.gis .gis-slider .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.gis .gis-slider input:checked + .slider {
  background-color: #38bec9;
}

.gis .gis-slider input:focus + .slider {
  box-shadow: 0 0 1px #38bec9;
}

.gis .gis-slider input:checked + .slider:before {
  transform: translateX(16px);
}

.gis .gis-slider .slider.round {
  border-radius: 34px;
}

.gis .gis-slider .slider.round:before {
  border-radius: 50%;
}

@media screen and (max-height: 800px) {
  .gis .sidebar .sidebar-buttons {
    padding: 15px 20px;
  }
}

@media screen and (max-width: 500px) {
  .gis .sidebar .sidebar-buttons {
    padding: 15px 20px;
  }
}

.selectbasemap {
  position: absolute;
  top: 10px;
  left: 40px;
  z-index: 996;
}

.selectbasemap .minimap {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

.selectbasemap .minimap .leaflet-container {
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.selectbasemap .minimap {
  display: inline-block;
  padding-left: 20px;
}

.selectbasemap .minimap .minimap-text {
  position: absolute;
  bottom: 0;
  width: 60px;
  z-index: 999;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 1px 0 0 rgba(0, 0, 0, 0.4), 0 -1px 0 rgba(0, 0, 0, 0.4),
    0 1px 0 rgba(0, 0, 0, 0.4), -1px 0 0 rgba(0, 0, 0, 0.4);
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
  pointer-events: none;
}

.selectbasemap .minimap.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.selectbasemap:hover .minimap.hide {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.selectbasemap .minimap .leaflet-control-attribution {
  display: none;
}

@media print {
  .selectbasemap {
    display: none;
  }
}


.mapviewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  padding-top: 50px;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) { 

.mapviewer { 
  padding-bottom: 60px;
}}
/* Datalakes Stylesheet
  ____  _____   __  _  _______      ______
_/ __ \ \__  \  \ \/ \/ /\__  \    /  ___/
\  ___/  / __ \_ \     /  / __ \_ /  /____
 \___  >(____  /  \/\_/  (____  / \___  /
	 \/      \/               \/      \/
      Developed By James Runnalls      */

/* ------------------- General --------------------------- */

@font-face {
  font-family: "The Serif Bold";
  src: url(/static/media/theserifbold.95cd92d9.woff) format("woff");
}

iframe {
  display: none;
}

body {
  color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin: 0;
  background-color: rgb(249, 249, 249);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  margin: 15px 0;
  padding: 0 10px;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 15px 0;
  padding: 0 10px;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

h3 {
  font-size: 21px;
  font-weight: 500;
}

h4 {
  margin: 5px 0;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  h1 {
    display: none;
  }
}

main {
  width: 960px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  min-height: calc(100vh - 205px);
}

@media screen and (max-width: 500px) {
  main {
    margin-bottom: 70px;
    margin-top: 40px;
    min-height: calc(100vh - 120px);
  }
}

#root {
  overflow: hidden;
}

.inline {
  display: inline-block;
}

/* Swagger */

.swagger-ui .opblock.opblock-get {
  border-color: #102a43 !important;
  background-color: transparent !important;
}

.swagger-ui .opblock .opblock-summary-path {
  min-width: 80px;
}

.swagger-ui .opblock-tag small {
  min-width: 80px;
}

.swagger-ui .opblock.opblock-get .opblock-summary-method {
  background-color: #102a43 !important;
}

.swagger-ui .opblock.opblock-post {
  border-color: #627d98 !important;
  background-color: transparent !important;
}

.swagger-ui .opblock.opblock-post .opblock-summary-method {
  background-color: #627d98 !important;
}

.swagger-ui .btn.authorize {
  border-color: #38bec9 !important;
  color: #38bec9 !important;
}

.swagger-ui .btn.authorize svg {
  fill: #38bec9 !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Text Area */

textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

/* Hyperlink */

a:link {
  color: black;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  text-decoration: underline;
}

button {
  border: solid 1px black;
  background-color: transparent;
  margin-right: 15px;
  margin-top: 15px;
  cursor: pointer;
  padding: 4px 8px;
}

/* Shapes */

.circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: black;
}

.square {
  height: 8px;
  width: 8px;
  background-color: black;
}

.triangle {
  height: 12px;
  width: 12px;
  -webkit-clip-path: polygon(0% 87%, 50% 0%, 50% 0%, 100% 87%);
  clip-path: polygon(0% 87%, 50% 0%, 50% 0%, 100% 87%);
  background-color: black;
}

.arrow {
  height: 12px;
  width: 12px;
  -webkit-clip-path: polygon(50% 0, 100% 100%, 50% 75%, 0 100%);
  clip-path: polygon(50% 0, 100% 100%, 50% 75%, 0 100%);
  background-color: black;
}

.trapezoid {
  height: 12px;
  width: 12px;
  -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  background-color: black;
}

.diamond {
  height: 12px;
  width: 12px;
  -webkit-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  background-color: black;
}

.pin {
  margin-left: -5px;
  margin-top: -23px;
  border-radius: 50% 50% 50% 0;
  border: 4px solid transparent;
  width: 15px;
  height: 15px;
  transform: rotate(-45deg);
}

.pin::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -6.5px;
  background-color: #fff;
}

.pin2 {
  position: absolute;
  top: -80%;
  left: 32%;
  border-radius: 50%;
  border: 8px solid red;
  width: 8px;
  height: 8px;
}

.pin2::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid red;
}

