.colormanipulation-outer {
  height: 100%;
  margin-bottom: 10px;
  background-color: rgb(249,249,249);
  border-radius: 5px;
}

.colormanipulation-headerbar {
  margin-bottom: 20px;
}

.colormanipulation-outer input[type="color"] {
  width: 100%;
}

.colormanipulation-header {
  display: inline-block;
  padding: 8px 18px;
  cursor: pointer;
}

.colormanipulation-header:hover {
  color: white;
  background-color: #38bec9;
}

.colormanipulation-header.header-active {
  color: white;
  background-color: #38bec9;
}

.colormanipulation-content {
  padding:5px
}
