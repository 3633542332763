.morphology {
  position: fixed;
  width: 100%;
  height: calc(100% - 110px);
  bottom: 0;
  left: 0;
  background-color: rgb(249, 249, 249);
  z-index: 9;
  padding: 10px;
  box-sizing: border-box;
}

.morphology .left {
  width: calc(100% - 420px);
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.morphology .right {
  height: 100%;
  z-index: 999;
  background-color: white;
  width: 410px;
  padding: 20px 10px;
  box-sizing: border-box;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  position: relative;
}

.morphology .lakes-map {
  height: calc(100% - 510px);
  min-height: 300px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 999;
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
}

.morphology .lakes-map #map {
  border-radius: 5px;
}

.morphology .lakes-map.full {
  height: calc(100% - 60px);
}

.morphology .lakes-map .morphology-loading {
  position: absolute;
  z-index: 999;
  width: 100%;
  margin-top: 250px;
}

.morphology .lake-morphology {
  height: 500px;
  width: 100%;
  background-color: white;
  padding: 5px 12px;
  box-sizing: border-box;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
}

.morphology .lake-morphology.short {
  height: 50px;
  padding: 10px;
}

.morphology .lake-morphology .lakes-graph-short {
  width: 100%;
  height: calc(100% - 90px);
}

.morphology .lake-morphology .banner {
  text-align: center;
  font-size: 22px;
}

.morphology .right .lake-list-header {
  height: 85px;
}

.morphology .right .lake-list-header input {
  width: calc(100% - 5px);
  border: solid 1px rgb(169, 169, 169);
  box-sizing: border-box;
  padding: 6px 15px 6px 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  margin-left: 5px;
}

.morphology .right .lake-list-header table th {
  cursor: pointer;
  border-bottom: 1px solid white;
}

.morphology .right .lake-list-header table th:hover {
  border-bottom: 1px solid black;
}

.morphology .right .lake-list-body {
  height: calc(100% - 70px);
  overflow-y: auto;
}

.lake-list-body table {
  border-collapse: collapse;
}

.lake-list-body table tr {
  cursor: pointer;
}

.lake-list-body table tr:nth-child(even) {
  background: #f3f3f3;
}
.lake-list-body table tr:nth-child(odd) {
  background: #fff;
}

.lake-list-body table tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.lake-list-body table td {
  padding: 5px 10px;
}

.lake-list-body table td.center {
  text-align: center;
}

.lakes-map-short {
  width: 100%;
  height: 350px;
}

.lake-morphology .interpolated {
  display: inline-block;
}

.lake-morphology .xselect {
  text-align: center;
  display: inline-block;
  margin: auto;
  width: calc(100% - 20px);
}

.lake-morphology .reference {
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  margin-top: 5px;
}

.lake-morphology .xselect .text {
  font-size: 16px;
  display: inline-block;
  margin-right: 10px;
}

.lake-morphology .xselect select {
  font-size: 16px;
  padding: 4px 6px;
  border-radius: 10px;
  background-color: white;
}

.lake-morphology .xselect button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.lake-morphology .xselect button:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .morphology .right {
    display: none;
  }
  .morphology .left {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .morphology {
    height: calc(100% - 150px);
    bottom: 70px;
    overflow-y: auto;
  }
  .lake-morphology {
    height: 400px;
  }
  .lakes-map {
    margin-bottom: 70px;
  }
  .morphology .lake-morphology.short {
    padding: 0 10px;
  }
  .morphology .lake-morphology .banner {
    font-size: 14px;
    padding: 6px;
  }
  .lake-morphology .xselect button {
    display: none;
  }
}
