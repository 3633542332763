.legend {
  z-index: 998;
  background-color: white;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 5px;
}

.legend.hide {
  width: auto;
}

.legend-inner {
  display: inline-block;
  max-width: 120px;
  margin: 2px 10px;
  text-align: center;
  vertical-align: top;
}

.legend-title {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #f2f5f7;
  border-radius: 5px 5px 0px 0px;
  font-weight: 700;
  text-align: center;
}

.legend.hide .legend-title {
  border: none;
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.legend-title-text {
  display: inline-block;
}

.legend-content {
  font-size: 12px;
  max-height: 350px;
  padding: 10px;
  overflow-y: auto;
}

.legend-content.hide {
  display: none;
}

.rasterdisplay-colorbar {
  width: 20px;
  height: 100%;
}

.rasterdisplay-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.markerdisplay-table {
  border-collapse: collapse;
  border-spacing: 0;
}

tr {
  height: 22px;
}

.rasterdisplay-innerlabel {
  font-size: 11px;
}

.rasterdisplay-bar {
  padding: 0;
  font-size: 8px;
}

.markerdisplay-symbol {
  line-height: 16px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .legend {
    max-width: calc(100% - 20px);
  }
}
