.layergroups .layergroups-content {
  max-height: calc(100vh - 457px);
  overflow-y: auto;
  background-color: white;
  box-shadow: 1px 1px 10px #c4bbbb inset;
  border-radius: 5px;
  padding-top: 15px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.layergroups .layergroups-content::-webkit-scrollbar {
  display: none;
}

.layergroups .layergroups-item {
  margin: 10px 8% 10px 8%;
  display: inline-block;
  width: 145px;
  vertical-align: top;
  max-width: 34%;
  cursor: pointer;
  text-align: center;
  color: #102a43;
}

.layergroups .layergroups-header {
  font-size: 18px;
  color: #102a43;
  text-align: center;
  margin-bottom: 25px;
}

.layergroups .layergroups-item img {
  width: 60%;
  margin-bottom: 10px;
}

.layergroups .layergroups-item img:hover {
  animation: shake 1s;
}

.layergroups .layergroups-welcome-message {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .layergroups .layergroups-welcome-message {
    font-size: 14px;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@media screen and (max-height: 800px) {
  .layergroups {
    max-height: calc(100vh - 250px);
  }
}

@media screen and (max-width: 500px) {
  .layergroups {
    max-height: calc(100vh - 310px);
    padding-bottom: 65px;
    box-sizing: border-box;
  }
}
