.home {
  margin-top: -20px;
}

.home-banner {
  width: 100%;
  background: url("../../img/banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  padding-top: 25vh;
  text-align: center;
  margin-bottom: 30px;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.homepagemap {
  width: 100%;
  height: 700px;
  max-height: 70vh;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-banner .home-tagline {
  font-size: 60px;
  line-height: 1.05;
  font-weight: 700;
  letter-spacing: -0.015em;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  width: 600px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
}

.home-banner button {
  font-size: 22px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  animation: pulse 2s 5;
  border-radius: 15px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.home-banner button:hover {
  transform: scale(1.05);
}

.home-partners {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}

.home-copyright {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 2px;
}

.home-partners img {
  height: 26px;
  margin: 5px 3%;
}

.home-partners .partner-logo .black {
  display: inline-block;
  opacity: 0.7;
}

.home-partners .partner-logo .color {
  display: none;
}

.home-partners .partner-logo:hover .black {
  display: none;
}

.home-partners .partner-logo:hover .color {
  display: inline-block;
}

.sectiontitle {
  width: 100%;
  margin-bottom: 120px;
  font-size: 36px;
  margin-top: 120px;
  color: rgb(134, 134, 139);
  font-family: "Arial", sans-serif;
  font-weight: bold;
  padding: 60px;
  box-sizing: border-box;
}

.sectiontitlemin {
  width: 100%;
  margin-bottom: 60px;
  font-size: 36px;
  margin-top: 120px;
  color: rgb(134, 134, 139);
  font-family: "Arial", sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.sectiontitle button {
  font-size: 18px;
  margin-right: 30px;
}

.sectiontitle button:hover {
  background-color: #e3e3e3;
}

.sectiontitle .sub {
  margin-top: 20px;
  color: black;
  font-size: 26px;
  margin-bottom: 10px;
}

.sectiontitle .scroll {
  font-size: 22px;
  margin: auto;
  margin-top: 30px;
}

.sectiontitle .arrow {
  transform: rotate(180deg);
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 20px;
  background-color: grey;
}

.sectiontitle .acc {
  margin-top: 15px;
}

.sectiontitle .letter {
  color: black;
  size: 50px;
  display: inline-block;
}

.sectiontitle .word {
  size: 14px;
  display: inline-block;
  padding-right: 10px;
}

.sectiontitle .lw {
  display: inline-block;
}

.section {
  position: relative;
}

.section.access {
  margin-top: -100px;
  margin-bottom: 100px;
}

.section.access a {
  text-decoration: none;
}

.section.access .box {
  width: 278px;
  border-radius: 20px;
  background-color: white;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  margin: 0 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(59, 65, 81, 0.3);
}

.section.access .box .box-header {
  background-color: #102a43;
  text-align: center;
  border-radius: 20px 20px 0 0;
  padding: 10px 0;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: white;
}

.section.access .box .box-img {
  padding: 40px 0;
  text-align: center;
}

.section.access .box .box-img img {
  height: 150px;
}

.section.access .box .box-text {
  padding: 0px 20px 20px 20px;
}

.section.access .box:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1000px) and (min-width: 650px) {
  .section.access .box {
    display: block;
    width: calc(100% - 100px);
    margin: 30px 50px;
  }
  .section.access .box .box-text {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    vertical-align: top;
    margin-top: 40px;
  }
  .section.access .box .box-img {
    display: inline-block;
    width: 50%;
    padding: 20px 0;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 650px) {
  .section.access {
    margin: 0;
  }
  .section.access .box {
    display: block;
    width: calc(100% - 60px);
    margin: 30px 30px;
  }
}

.section.lakesimulations {
  margin-bottom: 120px;
}

.section.lakesimulations img {
  width: 100%;
}

.section.lakesimulations .textbox {
  position: absolute;
  top: 0;
  right: 0;
}

.section.lakesimulations .outer {
  margin: 0;
}

.section.lakesimulations .textbox .straptext {
  text-align: right;
  font-size: 25px;
  margin-top: 30px;
  max-width: 712px;
  align-items: right;
  margin-bottom: 70px;
}

.section.lakesimulations .textbox .numberbox {
  display: inline-block;
  margin-left: 200px;
  font-size: 30px;
  text-align: center;
  color: grey;
}

.section.lakesimulations .textbox .numberbox .number {
  font-size: 90px;
  color: black;
}

.section.insitudata {
  margin-top: 50px;
}

.section.rs .img {
  width: 100%;
  background: url("../../img/rsmap.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 70vh;
  display: flex;
  margin-bottom: 120px;
  color: white;
}

.section.insitudata .img {
  width: 100%;
  background: url("../../img/lexplore.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 70vh;
  display: flex;
  margin-bottom: 120px;
}

.section.insitudata .img table {
  width: 100%;
  height: 100%;
}

.section.lakesimulations .img {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  background: url("../../img/zurich.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 120px;
  min-height: 70vh;
  margin-bottom: 120px;
  z-index: 999;
}

.section.insitudata .img table {
  font-size: 28px;
}

.section.lakesimulations .inner {
  float: right;
}

.section.lakesimulations a {
  text-decoration: none;
}

.section.lakesimulations button {
  display: block;
  font-size: 24px;
  border-radius: 15px;
  width: 300px;
  margin: auto;
  margin-top: 20px;
  background-color: white;
  color: black;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #bcccdc;
}

.section.lakesimulations button:hover {
  transform: scale(1.02);
}

.section.insitudata .inner {
  color: white;
}

.section .outer {
  font-size: 28px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  width: 960px;
  max-width: 100%;
  margin: auto;
  text-align: left;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.section .outer .inner {
  width: 50%;
  height: 100%;
}

.section.about {
  padding: 25px 50px;
  border-radius: 5px;
  background-color: white;
  font-size: 18px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgba(59, 65, 81, 0.3);
}

.section.about .contacts .contact {
  display: inline-block;
  width: 50%;
}

.section.about .contacts .contact img {
  width: 40%;
  display: inline-block;
  border-radius: 20px;
}

.section.about .contacts .contact .text {
  display: inline-block;
  width: 60%;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: top;
  margin-top: 20px;
  font-size: 14px;
}

.section.about .title {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #102a43;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.section.insitudata button {
  border: none;
  color: white;
  display: block;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 30px;
  background-color: #102a43;
}

.section.insitudata button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.section.rs button {
  border: none;
  color: white;
  display: block;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px 30px;
  background-color: #102a43;
}

.section.rs button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.section.insitudata a {
  text-decoration: none !important;
}

.section.rs a {
  text-decoration: none !important;
}

.section.insitudata .numberbox {
  display: inline-block;
  font-size: 30px;
  text-align: center;
  margin: 90px 0;
  color: white;
}

.section.insitudata .numberbox .number {
  font-size: 90px;
}

.section.rs {
  margin-top: 120px;
  margin-bottom: 120px;
  background: #f3f3f3;
}

.section.rs img {
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
}

.section.rs .text {
  font-size: 25px;
  margin: 60px 60px;
  padding: 40px 60px;
  border-bottom: 1px solid grey;
}

.triple {
  text-align: center;
  padding: 40px;
  background-color: #f3f3f3;
}

.triple-inner {
  width: 280px;
  display: inline-block;
  margin: 40px;
  vertical-align: top;
}

.triple-title {
  width: 100%;
  text-align: center;
  height: 40px;
  font-family: "The Serif Bold", Arial, sans-serif;
  font-size: 22px;
}

.triple-text {
  font-size: 18px;
  text-align: left;
}

.triple-img {
  text-align: center;
  height: 180px;
  vertical-align: middle;
  margin: 30px 0;
}

.triple-button {
  width: 100%;
  text-align: center;
}

.triple-button a button:hover {
  background-color: #d9d6d6;
}

.triple-img img {
  max-width: 100%;
  max-height: 100%;
}

.home-text .contact img {
  width: 150px;
}

@media screen and (max-width: 1300px) {
  .section.insitudata .numberbox {
    margin: 50px 0;
  }
}

@media screen and (max-width: 1050px) {
  .section.insitudata .title {
    font-size: 28px;
  }

  .section.insitudata .numberbox {
    margin: 20px 0;
  }

  .section.insitudata .numberbox .number {
    font-size: 70px;
  }
}

@media screen and (max-width: 960px) {
  .home-banner .home-tagline {
    font-size: 45px;
  }
  .home {
    padding: 0 20px;
  }
  .section.insitudata .title {
    font-size: 22px;
    margin-top: 20px;
  }

  .section.insitudata .numberbox {
    margin: 10px 0;
    font-size: 22px;
  }

  .section.insitudata .numberbox .number {
    font-size: 50px;
  }

  .section.lakesimulations .textbox .numberbox {
    margin-left: 50px;
    float: right;
  }
}

@media screen and (max-width: 500px) {
  .home {
    margin-top: 20px;
    padding: 0;
    margin-bottom: 100px;
  }
  .section.about .contacts .contact {
    width: 100%;
  }
  .section.about {
    box-sizing: border-box;
    width: 90%;
    margin-left: 5%;
    padding: 20px;
  }
  .home-banner {
    margin-bottom: 0;
    padding-top: 25vh;
  }
  .home-banner .home-tagline {
    font-size: 40px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .home-banner button {
    font-size: 16px;
  }

  .triple-inner {
    margin: 0;
  }

  .section.about .contacts .contact .text {
    padding: 0 10px;
    margin: 0;
  }

  .section.about .contacts .contact .text b {
    font-size: 12px;
  }

  .sectiontitle {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 25px;
  }

  .sectiontitle .sub {
    font-size: 18px;
  }

  .sectiontitle .scroll {
    font-size: 16px;
  }

  .sectiontitle .arrow {
    height: 20px;
    width: 20px;
  }

  .section.lakesimulations .textbox .straptext {
    font-size: 14px;
    width: 70%;
    float: right;
    padding-right: 20px;
  }
  .section.lakesimulations .textbox .numberbox {
    margin: 0 20px;
  }

  .section.lakesimulations .outer {
    font-size: 18px;
  }

  .section.lakesimulations .inner {
    width: 100%;
  }

  .section.rs .inner {
    width: 100%;
  }

  .section.insitudata {
    margin: 0;
  }

  .section.rs {
    margin: 0;
    margin-top: 20px;
  }

  .section.rs .text {
    padding: 20px;
    margin: 0;
    font-size: 18px;
  }

  .home-text {
    padding: 0 10px;
  }

  .home-text .contact img {
    width: 80px;
  }

  .home-copyright {
    margin-bottom: 65px;
  }
}
