.notfound {
    padding-top: 100px;
    padding-bottom: 100px;
}

.url {
    display: inline-block;
    font-weight: 700;
}

.number-error {
    font-size: 200px;
}