.dataset {
  margin: 20px 0;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-collapse: collapse;
}

.dataset:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dataset .dataset-top {
  padding: 20px 35px 20px 20px;
  position: relative;
  vertical-align: top;
}

.dataset .dataset-top .dataset-live {
  font-size: 10px;
  color: white;
  background-color: #14919b;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.dataset .dataset-top .buttons {
}

.dataset .dataset-top .buttons .button-main {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

.dataset .dataset-top .buttons .button-main:hover {
  transform: scale(1.05);
}

.dataset .dataset-top .buttons .button-sub {
  cursor: pointer;
  border: 1px solid #38bec9;
  display: inline-block;
  padding: 6px 16px;
  border-radius: 16px;
  margin-left: 16px;
  color: #38bec9;
  width: 90px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
}

.dataset .dataset-top .buttons .button-sub.grey {
  color: grey;
  border-color: grey;
}

.dataset .dataset-bottom {
  background-color: #f2f5f7;
  padding: 20px;
  color: #102a43;
  position: relative;
}

.dataset .dataset-bottom img.icon {
  height: 18px;
  margin-right: 8px;
  margin-bottom: -2px;
}

.dataset .dataset-bottom img.type {
  height: 46px;
  position: absolute;
  right: 12px;
  top: 18px;
}

.dataset .dataset-bottom .date-highlight {
  margin-top: 5px;
  max-width: calc(100% - 40px);
}

.dataset .dataset-top .dataset-title {
  font-size: 26px;
  margin-bottom: 10px;
}

.dataset .dataset-right {
  width: 160px;
  background-color: #102a43;
  vertical-align: top;
  padding: 20px 20px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.9);
}

.dataset .dataset-right .params {
  padding: 3px 0;
}

.search {
  position: relative;
}

.search img {
  position: absolute;
  left: 20px;
  top: 17px;
}

input.SearchBar {
  width: calc(100% - 10px);
  border: solid 1px rgb(169, 169, 169);
  box-sizing: border-box;
  padding: 6px 15px 6px 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 18px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  margin-left: 5px;
}

input.SearchBar::placeholder {
  font-size: 14px;
}

.download-selected .download-item {
  padding: 10px 20px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: 10px 0;
  font-weight: 700;
}

.download-inner button {
  display: inline-block;
  border: none;
  padding: 8px 16px;
  border-radius: 16px;
  background-color: #38bec9;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
}

table.time-selector {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  margin-top: 20px;
  margin-bottom: 10px;
}

table.time-selector input {
  width: 90%;
}

.download-multiple-title {
  font-size: 16px;
  font-weight: 700;
}

.selected-data-list {
  max-height: 60px;
  overflow: auto;
}

.MultipleDownload button {
  border: solid 1px black;
  border-radius: 5px;
  background-color: white;
  margin-right: 15px;
  margin-top: 5px;
  cursor: pointer;
  padding: 4px 8px;
  width: 120px;
}

.MultipleDownload button:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.download-container {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: none;
}

.download-container.show {
  display: block;
}

.datalist {
  display: none;
  margin-bottom: 120px;
}

.datalist.show {
  display: block;
}

.download-inner {
  padding: 5px 15px;
}

.download-close {
  width: 25px;
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: 700;
  font-size: 40px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.download-title {
  display: inline-block;
}

.download-top span {
  float: right;
  font-size: 18px;
}

.download-top {
  cursor: pointer;
}

.download-top h3 {
  border-bottom: 1px solid #c8c6bf;
  padding: 0 0 5px;
  margin: 0 0 16px;
  line-height: 20px;
}

.download-content {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.multi-select {
  padding: 5px 0;
}

.inner__control {
  border-color: black !important;
  cursor: text !important;
}

.inner__control:hover {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.inner__indicator.inner__dropdown-indicator {
  color: black !important;
}

.inner__indicator.inner__clear-indicator {
  cursor: pointer;
}

.sortbar {
  width: 100%;
  display: inline-block;
}

.sortbar select {
  display: inline-block;
  background-color: white;
  margin-left: 5px;
  float: right;
  margin: 2px;
  border-radius: 5px;
}

table.sortbar-table {
  display: inline-block;
  cursor: pointer;
}

table.sortbar-table td {
  padding: 3px 7px;
  border: solid 1px rgb(169, 169, 169);
  font-size: 13.333px;
  background-color: white;
  border-radius: 5px;
}

.characteristics {
  margin-top: 10px;
  border-bottom: 1px solid #c8c6bf;
  padding-bottom: 16px;
}

/* Filter Bar */

.filterbar {
  margin-top: 5px;
}

.filterbar .filterbar-item {
  padding: 2px 6px;
  width: fit-content;
  display: inline-block;
  border: 1px solid rgb(169, 169, 169);
  margin: 3px 8px 3px 0px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
}

.filterbar .filterbar-text {
  display: inline-block;
}

.filterbar .filterbar-x {
  display: inline-block;
  margin-left: 6px;
}

.filterboxinner {
  cursor: pointer;
}

.filterboxinner:hover {
  text-decoration: underline;
}

.popup {
  width: 100%;
  padding-right: 1px;
  border: 1px solid rgb(169, 169, 169);
  box-sizing: border-box;
  display: block;
  margin-bottom: 15px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.mapselecttitle {
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.hidepopup {
  width: 100%;
  height: 502px;
  display: none;
}

.dataportal-loading {
  text-align: center;
  margin-top: 100px;
}

.dataportal-message {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 500;
  padding: 40px 30px;
  background-color: white;
  border: solid 1px rgb(169, 169, 169);
  border-radius: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

@media screen and (max-width: 800px) {
  .dataset .innerdatasetleft {
    width: 100%;
  }

  .dataset .innerdatasetright {
    width: 100%;
  }

  .dataset .innerdatasetright .parameters-highlight {
    border: none;
    padding-left: 0;
    padding-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .sortbar select {
    padding: 4px 0;
  }
  table.sortbar-table td {
    padding: 6px 4px;
  }
  .checkbox {
    width: 14px;
    height: 14px;
  }
  .datalist {
    padding: 0 10px;
  }
  .sortbar {
    padding: 0 4px;
    box-sizing: border-box;
  }
  .filterbar {
    padding: 0 5px;
  }
  input.SearchBar {
    margin-bottom: 4px;
    margin-top: 2px;
  }
  .search img {
    left: 15px;
    top: 10px;
  }
  .dataset .dataset-top .dataset-title {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
  }
  .dataset .dataset-top .buttons .button-main {
    margin-top: 10px;
  }
  .dataset .dataset-top {
    padding: 20px 15px 15px 10px;
  }
  .dataset .dataset-right {
    width: 120px;
  }
  .dataset .dataset-top .buttons .button-sub {
    width: 60px;
    padding: 6px;
  }
}
